import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Capacitacion } from '../../entities/modulos/capacitacion';

const base_url = environment.url;
@Injectable({
  providedIn: 'root'
})
export class ModulosService {

  formGroup: FormGroup;


  constructor(protected http: HttpClient,
              protected router: Router,
              protected fb: FormBuilder) {

                this.formGroup = this.fb.group({
                  id_capacitacion: ['', [Validators.required]],
                  nombre: ['', [Validators.required]],
                  material: ['', [Validators.required]],
                  video: [''],
                  tarea: [''],
                  tipo: ['', [Validators.required]],
                  meet: ['', [Validators.required]],
                })      
              }   
             
              
              get form (): FormGroup { return this.formGroup; }
  

              set fillForm(herramienta: any) {
                this.formGroup.get('id_capacitacion').setValue(herramienta.id_capacitacion)
                this.formGroup.get('nombre').setValue(herramienta.nombre)
                this.formGroup.get('material').setValue(herramienta.material)
                this.formGroup.get('video').setValue(herramienta.video)
                this.formGroup.get('tarea').setValue(herramienta.tarea)
                this.formGroup.get('tipo').setValue(herramienta.tipo)
                this.formGroup.get('meet').setValue(herramienta.meet)
                }
              


  cargarModulos(desde: number = 0){
    const url = `${ base_url}unidad/show?desde=${desde}`; 
    return this.http.get<any>(url)
  }


  // cargarCategorias(desde: number = 0){
  //   const url = `${ base_url}cursoCapacitacion/show?desde=${desde}`; 
  //   return this.http.get<any>(url)
  // }


  getById(trabajo: any): Observable<any> {
    return this.http.post<any>(environment.baseUrl + 'unidad/showByID', trabajo)
  }


  getByIdCapacitacion(trabajo: any): Observable<any> {
    return this.http.post<any>(environment.baseUrl + 'unidad/showByIDCapacitacion', trabajo)
  }

  create(data: any): Observable<any> {
    const fd = new FormData();
    fd.append('id_capacitacion',data.id_capacitacion);
    fd.append('nombre',data.nombre);
    fd.append('material',data.material);
    fd.append('video',data.video);
    fd.append('tarea',data.tarea);
    fd.append('tipo',data.tipo);
    fd.append('meet',data.meet);
    return this.http.post<any>(`${environment.baseUrl}unidad`, fd)
  }

  edit(trabajo: any): Observable<any> {
    return this.http.post<any>(environment.baseUrl + 'unidad/update/' + trabajo._id, trabajo);
  }

  delete(herramienta: any): Observable<any> {
    return this.http.delete<any>(environment.baseUrl + 'unidad/' + herramienta._id)
  }


  export(): Observable<any> {
    return this.http.get<any>(environment.baseUrl + 'unidad/exportar')
  }





}
