import { Component, OnInit } from '@angular/core';

import Swal from 'sweetalert2';
import { TareasService } from '../../services/tareas.service';

@Component({
  selector: 'app-resultado-list',
  templateUrl: './resultado-list.component.html',
  styleUrls: ['./resultado-list.component.scss']
})
export class ResultadoListComponent  implements OnInit {

  public cargando: boolean = true;
  public desde: number = 0;
  public resultado: any[] = [];
  public resultadoTemp: any[] = [];
  public totalResultados: number = 0;

 
  public exportarResultados : any[] = [];

  constructor(
  public resultadosServices: TareasService) { }

  ngOnInit(): void {
    this.cargarresultadosTodos();

    this.exportarResuls();
  }



  cargarresultadosTodos(){
    this.cargando = true; 
    this.resultadosServices.cargarResultados(this.desde)
    .subscribe(({total, resultado}) => {
        this.totalResultados = total;
        if(resultado.length !== 0) { 
          this.resultado = resultado;
          console.log(resultado);
          console.log('resultado');

          this.resultadoTemp = resultado;
        }
        this.cargando = false;
    })
  }


  eliminarResultado(resultado){
    console.log(resultado);
    Swal.fire({
      title: 'Borrar Resultado?',
      text: `Esta a punto de borrar la Resultado` ,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo!'
    }).then((result) => {
      if (result.value) {
        this.resultadosServices.eliminarResultado(resultado)
        .subscribe(resp => {
        this.cargarresultadosTodos();
        Swal.fire('Resultado Borrado',`El resultado fue eliminado correctamente` , 'success')
      } 
      );
      }
    })  
  }



  cambiarPagina( valor: number) {
    this.desde += valor;

    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalResultados) {
      this.desde -= valor;
    }

    this.cargarresultadosTodos();
  }




  
  exportarResuls(){
    // this.resultadosServices.exportarResultados()
    // .subscribe(({total,resultado}) => {
    //     this.exportarResultados = resultado;
    //     console.log(resultado);
    //   });  
  }




}