import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Herramienta } from 'src/app/entities/modulos/herramienta';
import { SnackbarHelper } from 'src/app/utils/helpers/snackbar-helper';
import { Repositorio } from 'src/app/entities/modulos/repositorio';
import { RepositoriosService } from '../../services/repositorios.service';
import { Recurso } from 'src/app/entities/modulos/recurso';
import { RecursoService } from '../../services/recurso.service';

@Component({
  selector: 'app-delete-recurso',
  templateUrl: './delete-recurso.component.html',
  styleUrls: ['./delete-recurso.component.scss']
})
export class DeleteRecursoComponent {
  @Input() recurso: Recurso
  deleted = false
  deleting = false

  constructor(protected activeModal: NgbActiveModal,
    protected recursoService: RecursoService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }


  closeModal() {
    this.activeModal.close(true)
  }

  delete() {
    this.deleting=true
    this.recursoService.delete(this.recurso).subscribe(data => {

      SnackbarHelper.show(this.snackBar, { msg: 'Eliminado con éxito', })

      console.log(data);
      this.deleted = true
      this.deleting=false
      this.closeModal()
    })  
  }


}
