import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Empleado } from 'src/app/entities/modulos/empleado';
import { Util } from 'src/app/utils/helpers/util';
import { CreateClienteComponent } from '../modal/create-cliente/create-cliente.component';
import { DeleteClienteComponent } from '../modal/delete-cliente/delete-cliente.component';
import { EditClienteComponent } from '../modal/edit-cliente/edit-cliente.component';
import { ClienteService } from '../services/cliente.service';
import { Cell, PdfMakeWrapper, Table, Txt, QR, Img } from 'pdfmake-wrapper';
import { LoginService } from 'src/app/services/security/login.service';

@Component({
  selector: 'app-cliente-list',
  templateUrl: './cliente-list.component.html',
  styleUrls: ['./cliente-list.component.scss']
})
export class ClienteListComponent implements OnInit {

  public data: any = [];


  public cliente: any[] = [];
  public clienteTemp: any[] = [];
  
  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalCliente: number = 0;


  constructor(public clienteService: ClienteService,
    protected loginService: LoginService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.cargarCliente();
  }


  cargarCliente(){
    this.cargando = true; 
    this.clienteService.cargarClientes(this.desde)
    .subscribe(({total, usuario}) => {
        console.log(usuario);
        this.totalCliente = total;
        if(usuario.length !== 0) { 
          this.cliente = usuario;
          this.clienteTemp = usuario;
        }
        this.cargando = false;
    })
  }


  openCreate(){
    const modalRef = this.modalService.open(CreateClienteComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then(res => {
        this.cargarCliente();
      })
  }


  openEdit(data: Empleado) {
    const modalEdit = this.modalService.open(EditClienteComponent, { size: 'lg', backdrop: 'static' })
    modalEdit.componentInstance.cliente = data
    modalEdit.result.then(res => {
      this.cargarCliente();
    })
  }

  openDelete(data: Empleado) {
    const deleteModal = this.modalService.open(DeleteClienteComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.cliente = data
    deleteModal.result.then(res => {
      this.cargarCliente();
    })
  }

  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalCliente) {
      this.desde -= valor;
    }
    this.cargarCliente();
  }


  export(){
    this.clienteService.export()
        .subscribe(res => { Util.download(res['usuario'], 'usuarios'); console.log(res)});
  }


  buscar(termino: string) {
    if(termino.length === 0 ) {
      return this.cliente = this.clienteTemp;
    }
    this.clienteService.buscar('dni', termino)
    .subscribe(resultados => {
      this.cliente = resultados;
    });
  }



  buscar2(termino2: string) {
    if(termino2.length === 0 ) {
      return this.cliente = this.clienteTemp;
    }
    this.clienteService.buscar('nombre', termino2)
    .subscribe(resultados => {
      this.cliente = resultados;
    });
  }



  
  async imprimirQR(data: any){
      var titulo = 'I.E RAMIRO PRIALE PRIALE';
      var espacio = '   ';
      const pdf = new PdfMakeWrapper();
      pdf.pageSize('A7');
      pdf.pageMargins([ 1, 1, 4, 1 ]);
      pdf.info({
        title: 'Ramiro Priales - Reporte',
        author: 'ramiro_p',
        subject: 'subject of document',
      });
      pdf.defaultStyle({
        fontSize: 10
      });
      pdf.add( new Txt(espacio).end);
      pdf.add( new Txt(espacio).end);
      pdf.add( new Txt(titulo).alignment('center').bold().italics().end);
      pdf.add( new Txt('RUC. 20604943231 ').alignment('center').italics().end);
      pdf.add( new Txt(espacio).end);
      pdf.add( new Txt('MI CARD ID').alignment('center').bold().italics().end);
      pdf.add( new Txt('------------------------------------------').alignment('center').italics().end);
      pdf.add( new Txt(espacio).end);
      pdf.add(new QR(data.dni).fit(120).alignment('center').end);
      pdf.add( new Txt(espacio).end);
      pdf.add( new Txt('------------------------------------------').alignment('center').italics().end);
      pdf.add( new Txt(data.nombre + ' ' + data.apellidos).alignment('center').bold().italics().end);
      pdf.add( new Txt(data.dni).alignment('center').italics().end);
      pdf.add( new Txt(data.email).alignment('center').italics().end);
      pdf.create().print();
  }


}