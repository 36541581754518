import { Component, OnInit } from '@angular/core';

import Swal from 'sweetalert2';
import { TareasService } from '../../services/tareas.service';
import { ClienteService } from 'src/app/proyectos/cliente/services/cliente.service';

@Component({
  selector: 'app-reporte-alumno',
  templateUrl: './reporte-alumno.component.html',
  styleUrls: ['./reporte-alumno.component.scss']
})
export class ReporteAlumnoComponent   implements OnInit {
  public cargando: boolean = true;
  public desde: number = 0;
  public resultado: any[] = [];
  public resultadoTemp: any[] = [];
  public totalResultados: number = 0;

 
  public exportarResultados : any[] = [];


  public usuarios: any = [];
  public resultados: any = [];

  constructor(
  public resultadosServices: TareasService,
  public userService: ClienteService) { }

  ngOnInit(): void {
    this.cargarresultadosTodos();

    this.exportarResuls();

    this.cargarUsuarios();
  }



  cargarresultadosTodos(){
    this.cargando = true; 
    this.resultadosServices.cargarResultados(this.desde)
    .subscribe(({total, resultado}) => {
        this.totalResultados = total;
        if(resultado.length !== 0) { 
          this.resultado = resultado;
          console.log(resultado);
          console.log('resultado');

          this.resultadoTemp = resultado;
        }
        this.cargando = false;
    })
  }


  cargarUsuarios(){
    this.userService.export().subscribe( resp => {
      console.log(resp['usuario']);
      this.usuarios = resp['usuario'];
    })
  }

  eliminarResultado(resultado){
    console.log(resultado);
    Swal.fire({
      title: 'Borrar Resultado?',
      text: `Esta a punto de borrar la Resultado` ,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo!'
    }).then((result) => {
      if (result.value) {
        this.resultadosServices.eliminarResultado(resultado)
        .subscribe(resp => {
        this.cargarresultadosTodos();
        Swal.fire('Resultado Borrado',`El resultado fue eliminado correctamente` , 'success')
      } 
      );
      }
    })  
  }



  cambiarPagina( valor: number) {
    this.desde += valor;

    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalResultados) {
      this.desde -= valor;
    }

    this.cargarresultadosTodos();
  }


  onSelectionChange(data: any) {
    console.log(data.value);
    this.resultadosServices.cargarResultadosByID({usuario_id: data.value}).subscribe(resp => {
      console.log(resp);
      this.resultados = resp['resultado'];
    })
  }



  
  exportarResuls(){
    // this.resultadosServices.exportarResultados()
    // .subscribe(({total,resultado}) => {
    //     this.exportarResultados = resultado;
    //     console.log(resultado);
    //   });  
  }




}