import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { HerramientasService } from '../services/herramientas.service';
import { Herramienta } from '../../entities/modulos/herramienta';
import { environment } from 'src/environments/environment';
import { RecursoService } from '../services/recurso.service';
interface HtmlInputEvent extends Event{
  target : HTMLInputElement & EventTarget;
} 



@Component({
  selector: 'app-recurso-form',
  templateUrl: './recurso-form.component.html',
  styleUrls: ['./recurso-form.component.scss']
})
export class RecursoFormComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Output() submitEvent = new EventEmitter<Herramienta>();
  @Output() closeEvent =  new EventEmitter<boolean>();
  @Input() disableControl: boolean;
  @Input() formTitle: string;
  refreshTable = false;
  statusActive: boolean = false;
  public file :File;
  public imgSelect : String | ArrayBuffer = `${environment.cloudinary}recursos/default_go6lhc.JPG`;


  public categorias: any [];

  public carpeta_id = localStorage.getItem('carpeta_id')

  constructor(protected fb: FormBuilder,
    protected activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public recursosService: RecursoService){

  }


  ngOnInit(): void {
    this.cargarCatgeorias();
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.formGroup.reset();
    if(this.formTitle === 'EDITAR HERRAMIENTA'){
      // this.formGroup.get('username').disable();
      this.statusActive = false;
      // this.formLDAP = false;
    } else{
      // this.newUser = false;
      this.statusActive = true;

    }
    
  }

  cargarCatgeorias(){
    // this.herramientaService.cargarCategorias().subscribe(resp => {
    //   console.log(resp.categoria);
    //   this.categorias = resp.categoria;
    // })
  }


  onSubmit() {
    console.log(this.formGroup.value);
    if (this.formGroup.valid) {
      this.submitEvent.emit(this.formGroup.value)
      this.formGroup.reset();
    }
  }



  closeMOdal() {
    this.closeEvent.emit(this.refreshTable)
    this.modalService.dismissAll();
  }
  


  imgSelected(event: HtmlInputEvent){
    if(event.target.files  && event.target.files[0]){
        this.file = <File>event.target.files[0];

        const reader = new FileReader();
        // reader.onload = e => this.imgSelect= reader.result;
        reader.readAsDataURL(this.file);
      //  console.log(this.file);
       this.formGroup.controls.imagen.setValue(this.file)
        
    }
    
  }


}
