import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { CategoriasService } from '../../services/categorias.service';
import { Grado } from 'src/app/entities/modulos/grado';
import { Util } from 'src/app/utils/helpers/util';
import { Area } from 'src/app/entities/modulos/area';
import { GradosService } from '../../services/grados.service';
import { CreateCategoriaComponent } from '../modal/create-categoria/create-categoria.component';
import { EditCategoriaComponent } from '../modal/edit-categoria/edit-categoria.component';
import { DeleteCategoriaComponent } from '../modal/delete-categoria/delete-categoria.component';


@Component({
  selector: 'app-categoria-list',
  templateUrl: './categoria-list.component.html',
  styleUrls: ['./categoria-list.component.scss']
})
export class CategoriaListComponent  implements OnInit {


  public data: any = [];
  public categoria: any[] = [];
  public categoriaTemp: any[] = [];

proyectos: [] = [];


  public IMG =  environment.cloudinary;
  
  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalTrabajo: number = 0;

  public grados: any[];

  constructor(public areaService: CategoriasService,
    public gradopsService: GradosService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.cargarHerramientas();
    this.cargarGrados();
  }

  cargarGrados(){
    this.gradopsService.export().subscribe(resp => {
      this.grados = resp['data'];
      // console.log(resp['data']);
    })
  }

  cargarHerramientas(){
    this.cargando = true; 
    this.areaService.cargarCategoria(this.desde)
    .subscribe(({total, categoria}) => {
        this.totalTrabajo = total;
        if(categoria.length !== 0) { 
          this.categoria = categoria;
          console.log(categoria);
          this.categoriaTemp = categoria;
        }
        this.cargando = false;
    })
  }





  openCreate(){
    const modalRef = this.modalService.open(CreateCategoriaComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then(res => {
        this.cargarHerramientas();
      })
  }



  openEdit(data: Area) {
    const modalEdit = this.modalService.open(EditCategoriaComponent, { size: 'lg', backdrop: 'static' })
    modalEdit.componentInstance.categoria = data
    modalEdit.result.then(res => {
      this.cargarHerramientas();
    })
  }

  openDelete(data: Area) {
    const deleteModal = this.modalService.open(DeleteCategoriaComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.categoria = data
    deleteModal.result.then(res => {
      this.cargarHerramientas();
    })
  }

  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalTrabajo) {
      this.desde -= valor;
    }
    this.cargarHerramientas();
  }


  export(){
    this.areaService.export()
        .subscribe(res => { Util.download(res['data'], 'categorias'); console.log(res)});
  }


  buscar(termino: string) {
    // if(termino.length === 0 ) {
    //   return this.trabajo = this.trabajoTemp;
    // }
    // this.trabajoService.buscar('trabajo', termino)
    // .subscribe(resultados => {
    //   this.trabajo = resultados;
    // });
  }
  


}
