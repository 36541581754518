import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RecursoService } from 'src/app/repositorios/services/recurso.service';
import { ModulosService } from 'src/app/capacitaciones/services/modulos.service';
import { Util } from 'src/app/utils/helpers/util';
import { DeleteModuloComponent } from '../modal/delete-modulo/delete-modulo.component';
import { EditModuloComponent } from '../modal/edit-modulo/edit-modulo.component';
import { CreateModuloComponent } from '../modal/create-modulo/create-modulo.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modulos-list',
  templateUrl: './modulos-list.component.html',
  styleUrls: ['./modulos-list.component.scss']
})
export class ModulosListComponent implements OnInit {

  public idModule : any;



  public data: any = [];
  public unidad: any[] = [];
  public unidadTemp: any[] = [];

proyectos: [] = [];


  public IMG;
  public REC;
  
  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalUnidad: number = 0;



  constructor(public recursoService: RecursoService,
    private route: ActivatedRoute,
    public moduloService: ModulosService,
    public router: Router,
    private modalService: NgbModal){
      this.route.params.subscribe(params => {
        this.idModule= params['id'];

        localStorage.setItem('unidad_id', params['id']);

        console.log(this.idModule);
      });
  }


  ngOnInit(): void {
    this.cargarModulos();
    this.REC = environment.cloudinary+'unidades/';
    
  }



  cargarModulos(){
    this.cargando = true; 
    this.moduloService.getByIdCapacitacion({id_capacitacion: this.idModule})
    .subscribe((unidad) => {
        // this.totalUnidad = total;
        // if(unidad.length !== 0) { 
          this.unidad = unidad.unidad;
        //   console.log(unidad);
        //   this.unidadTemp = unidad;
        // }
        // this.cargando = false;
    })
  }





  openCreate(){
    const modalRef = this.modalService.open(CreateModuloComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then(res => {
        this.cargarModulos();
      })
  }



  openEdit(data: any) {
    const modalEdit = this.modalService.open(EditModuloComponent, { size: 'lg', backdrop: 'static' })
    modalEdit.componentInstance.unidad = data
    modalEdit.result.then(res => {
      this.cargarModulos();
    })
  }

  openDelete(data: any) {
    const deleteModal = this.modalService.open(DeleteModuloComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.unidad = data
    deleteModal.result.then(res => {
      this.cargarModulos();
    })
  }

  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalUnidad) {
      this.desde -= valor;
    }
    this.cargarModulos();
  }


  export(){
    this.moduloService.export()
        .subscribe(res => { Util.download(res['data'], 'unidades'); console.log(res)});
  }


  buscar(termino: string) {
    // if(termino.length === 0 ) {
    //   return this.trabajo = this.trabajoTemp;
    // }
    // this.trabajoService.buscar('trabajo', termino)
    // .subscribe(resultados => {
    //   this.trabajo = resultados;
    // });
  }
  


  openAddModulo(data: any){
    console.log(data);
    this.router.navigate(["/admin/modulos.list/"+data._id])
  }





}