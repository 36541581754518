import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HerramientaRoutingModule } from './herramienta-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UtilsModule } from '../utils/utils.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HerramientaListComponent } from './herramienta-list/herramienta-list.component';
import { HerramientaFormComponent } from './herramienta-form/herramienta-form.component';
import { CreateHerramientaComponent } from './modal/create-herramienta/create-herramienta.component';
import { EditHerramientaComponent } from './modal/edit-herramienta/edit-herramienta.component';
import { BrowserModule } from '@angular/platform-browser';
import { DeleteHerramientaComponent } from './modal/delete-herramienta/delete-herramienta.component';


@NgModule({
  declarations: [
    HerramientaListComponent,
    CreateHerramientaComponent,
    EditHerramientaComponent,
    DeleteHerramientaComponent,
    HerramientaFormComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HerramientaRoutingModule,
    ReactiveFormsModule,

   
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    UtilsModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatPaginatorModule,
    NgbModule,
  ]
})
export class HerramientaModule { }
