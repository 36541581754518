import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Grado } from '../../entities/modulos/grado';
import { Observable } from 'rxjs';

const base_url = environment.url;



@Injectable({
  providedIn: 'root'
})
export class GradosService {


  formGroup: FormGroup;


  constructor(protected http: HttpClient,
    protected router: Router,
    protected fb: FormBuilder) {

      this.formGroup = this.fb.group({
        nombre: ['', [Validators.required]],
      })      
    }  
    get form (): FormGroup { return this.formGroup; }


    set fillForm(grado: Grado) {
      this.formGroup.get('nombre').setValue(grado.nombre)
      }


      cargarGrado(desde: number = 0){
        const url = `${ base_url}grado/show?desde=${desde}`; 
        return this.http.get<any>(url)
      }

      getById(trabajo: Grado): Observable<Grado> {
        return this.http.post<Grado>(environment.baseUrl + 'grado/showByID', trabajo)
      }
    
      create(data: Grado): Observable<Grado> {
        const fd = new FormData();
        fd.append('nombre',data.nombre);
        return this.http.post<Grado>(`${environment.baseUrl}grado`, fd)
      }
    
      edit(grado: Grado): Observable<Grado> {
        return this.http.post<Grado>(environment.baseUrl + 'grado/update/' + grado._id, grado);
      }
    
      delete(grado: Grado): Observable<Grado> {
        return this.http.delete<Grado>(environment.baseUrl + 'grado/' + grado._id)
      }
    
    
      export(): Observable<Grado> {
        return this.http.get<Grado>(environment.baseUrl + 'grado/exportar')
      }


}
