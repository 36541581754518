import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GradosService } from 'src/app/configuraciones/services/grados.service';
import { Grado } from 'src/app/entities/modulos/grado';
import { SnackbarHelper } from 'src/app/utils/helpers/snackbar-helper';

@Component({
  selector: 'app-delete-grado',
  templateUrl: './delete-grado.component.html',
  styleUrls: ['./delete-grado.component.scss']
})
export class DeleteGradoComponent {
  @Input() grado: Grado
  deleted = false
  deleting = false

  constructor(protected activeModal: NgbActiveModal,
    protected gradosService: GradosService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }


  closeModal() {
    this.activeModal.close(true)
  }

  delete() {
    this.deleting=true
    this.gradosService.delete(this.grado).subscribe(data => {

      SnackbarHelper.show(this.snackBar, { msg: 'Eliminado con éxito', })

      console.log(data);
      this.deleted = true
      this.deleting=false
      this.closeModal()
    })  
  }


}
