import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { HerramientasService } from '../services/herramientas.service';
import { Trabajo } from 'src/app/entities/modulos/trabajo';
import { environment } from 'src/environments/environment';

import { CreateHerramientaComponent } from '../modal/create-herramienta/create-herramienta.component';
import { DeleteHerramientaComponent } from '../modal/delete-herramienta/delete-herramienta.component';
import { EditHerramientaComponent } from '../modal/edit-herramienta/edit-herramienta.component';
import { Herramienta } from 'src/app/entities/modulos/herramienta';
import { Util } from 'src/app/utils/helpers/util';


@Component({
  selector: 'app-herramienta-list',
  templateUrl: './herramienta-list.component.html',
  styleUrls: ['./herramienta-list.component.scss']
})
export class HerramientaListComponent implements OnInit {


  public data: any = [];
  public herramienta: any[] = [];
  public herramientasTemp: any[] = [];

proyectos: [] = [];


  public IMG =  environment.cloudinary;
  
  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalTrabajo: number = 0;

  constructor(public trabajoService: HerramientasService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.cargarHerramientas();
  }


  cargarHerramientas(){
    this.cargando = true; 
    this.trabajoService.cargarHerramienta(this.desde)
    .subscribe(({total, herramienta}) => {
        this.totalTrabajo = total;
        if(herramienta.length !== 0) { 
          this.herramienta = herramienta;
          console.log(herramienta);
          this.herramientasTemp = herramienta;
        }
        this.cargando = false;
    })
  }





  openCreate(){
    const modalRef = this.modalService.open(CreateHerramientaComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then(res => {
        this.cargarHerramientas();
      })
  }



  openEdit(data: Trabajo) {
    const modalEdit = this.modalService.open(EditHerramientaComponent, { size: 'lg', backdrop: 'static' })
    modalEdit.componentInstance.trabajo = data
    modalEdit.result.then(res => {
      this.cargarHerramientas();
    })
  }

  openDelete(data: Herramienta) {
    const deleteModal = this.modalService.open(DeleteHerramientaComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.herramienta = data
    deleteModal.result.then(res => {
      this.cargarHerramientas();
    })
  }

  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalTrabajo) {
      this.desde -= valor;
    }
    this.cargarHerramientas();
  }


  export(){
    this.trabajoService.export()
        .subscribe(res => { Util.download(res['data'], 'herramientas'); console.log(res)});
  }


  buscar(termino: string) {
    if(termino.length === 0 ) {
      return this.herramienta = this.herramientasTemp;
    }
    this.trabajoService.buscar('nombreH', termino)
    .subscribe(resultados => {
      this.herramienta = resultados;
    });
  }
  


}
