import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Grado } from 'src/app/entities/modulos/grado';
import { environment } from 'src/environments/environment';
import { CarpetaService } from '../services/carpeta.service';
import { AreasService } from 'src/app/configuraciones/services/areas.service';
import { GradosService } from 'src/app/configuraciones/services/grados.service';
import { Repositorio } from 'src/app/entities/modulos/repositorio';
import { RepositoriosService } from '../services/repositorios.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-carpeta-form',
  templateUrl: './carpeta-form.component.html',
  styleUrls: ['./carpeta-form.component.scss']
})
export class CarpetaFormComponent  implements OnInit {

  @Input() formGroup: FormGroup;
  @Output() submitEvent = new EventEmitter<Grado>();
  @Output() closeEvent =  new EventEmitter<boolean>();
  @Input() disableControl: boolean;
  @Input() formTitle: string;
  refreshTable = false;
  statusActive: boolean = false;

  public areas: any [];
  public grados: any [];
  public repositorios: any [];



  constructor(protected fb: FormBuilder,
    protected activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public carpetaService: CarpetaService,
    public areaService: AreasService,
    public gradoService: GradosService,
    private route: ActivatedRoute,
    public repositorioService: RepositoriosService){

  }


  ngOnInit(): void {
    


    this.cargarAreas();
    this.cargarGrados();
    this.cargarRepositorios();
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.formGroup.reset();
    if(this.formTitle === 'EDITAR CARPETA'){
      // this.formGroup.get('username').disable();
      this.statusActive = false;
      // this.formLDAP = false;
    } else{
      // this.newUser = false;
      this.statusActive = true;

    }
    
  }

  cargarAreas(){
    this.areaService.export().subscribe(resp => {
      console.log(resp['data']);
      this.areas = resp['data'];
    })
  }

  cargarGrados(){
    this.gradoService.export().subscribe(resp => {
      console.log(resp['data']);
      this.grados = resp['data'];
    })
  }

  cargarRepositorios(){
    this.repositorioService.export().subscribe(resp => {
      this.repositorios = resp['data'];
    })
  }


  onSubmit() {
    console.log(this.formGroup.value);
    if (this.formGroup.valid) {
      this.submitEvent.emit(this.formGroup.value)
      this.formGroup.reset();
    }
  }



  closeMOdal() {
    this.closeEvent.emit(this.refreshTable)
    this.modalService.dismissAll();
  }


}

