import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Area } from '../../entities/modulos/area';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
const base_url = environment.url;

@Injectable({
  providedIn: 'root'
})
export class AreasService {

  formGroup: FormGroup;


  constructor(protected http: HttpClient,
    protected router: Router,
    protected fb: FormBuilder) {

      this.formGroup = this.fb.group({
        nombre: ['', [Validators.required]],
        // grado_id: ['', [Validators.required]],
      })      
    }  
    get form (): FormGroup { return this.formGroup; }


    set fillForm(grado: Area) {
      this.formGroup.get('nombre').setValue(grado.nombre)
      // this.formGroup.get('grado_id').setValue(grado.grado_id)
      }


      cargarGrado(desde: number = 0){
        const url = `${ base_url}area/show?desde=${desde}`; 
        return this.http.get<any>(url)
      }

      getById(area: Area): Observable<Area> {
        return this.http.post<Area>(environment.baseUrl + 'area/showByID', area)
      }
    
      create(data: Area): Observable<Area> {
        const fd = new FormData();
        fd.append('nombre',data.nombre);
        return this.http.post<Area>(`${environment.baseUrl}area`, fd)
      }
    
      edit(grado: Area): Observable<Area> {
        return this.http.post<Area>(environment.baseUrl + 'area/update/' + grado._id, grado);
      }
    
      delete(grado: Area): Observable<Area> {
        return this.http.delete<Area>(environment.baseUrl + 'area/' + grado._id)
      }
    
    
      export(): Observable<Area> {
        return this.http.get<Area>(environment.baseUrl + 'area/exportar')
      }



      exportCarpetas(): Observable<any> {
        return this.http.get<any>(environment.baseUrl + 'carpeta/exportar')
      }


}