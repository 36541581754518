import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AreasService } from '../../services/areas.service';
import { Grado } from 'src/app/entities/modulos/grado';
import { Util } from 'src/app/utils/helpers/util';
import { EditAreaComponent } from '../modal/edit-area/edit-area.component';
import { CreateAreaComponent } from '../modal/create-area/create-area.component';
import { DeleteAreaComponent } from '../modal/delete-area/delete-area.component';
import { Area } from 'src/app/entities/modulos/area';
import { GradosService } from '../../services/grados.service';

@Component({
  selector: 'app-areas-list',
  templateUrl: './areas-list.component.html',
  styleUrls: ['./areas-list.component.scss']
})
export class AreasListComponent  implements OnInit {


  public data: any = [];
  public herramienta: any[] = [];
  public herramientasTemp: any[] = [];

proyectos: [] = [];


  public IMG =  environment.cloudinary;
  
  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalTrabajo: number = 0;

  public grados: any[];

  constructor(public areaService: AreasService,
    public gradopsService: GradosService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.cargarHerramientas();
    this.cargarGrados();
  }

  cargarGrados(){
    this.gradopsService.export().subscribe(resp => {
      this.grados = resp['data'];
      // console.log(resp['data']);
    })
  }

  cargarHerramientas(){
    this.cargando = true; 
    this.areaService.cargarGrado(this.desde)
    .subscribe(({total, area}) => {
        this.totalTrabajo = total;
        if(area.length !== 0) { 
          this.herramienta = area;
          console.log(area);
          this.herramientasTemp = area;
        }
        this.cargando = false;
    })
  }





  openCreate(){
    const modalRef = this.modalService.open(CreateAreaComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then(res => {
        this.cargarHerramientas();
      })
  }



  openEdit(data: Area) {
    const modalEdit = this.modalService.open(EditAreaComponent, { size: 'lg', backdrop: 'static' })
    modalEdit.componentInstance.area = data
    modalEdit.result.then(res => {
      this.cargarHerramientas();
    })
  }

  openDelete(data: Area) {
    const deleteModal = this.modalService.open(DeleteAreaComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.area = data
    deleteModal.result.then(res => {
      this.cargarHerramientas();
    })
  }

  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalTrabajo) {
      this.desde -= valor;
    }
    this.cargarHerramientas();
  }


  export(){
    this.areaService.export()
        .subscribe(res => { Util.download(res['data'], 'grados'); console.log(res)});
  }


  buscar(termino: string) {
    // if(termino.length === 0 ) {
    //   return this.trabajo = this.trabajoTemp;
    // }
    // this.trabajoService.buscar('trabajo', termino)
    // .subscribe(resultados => {
    //   this.trabajo = resultados;
    // });
  }
  


}
