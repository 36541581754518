import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

const base_url = environment.baseUrl;


@Injectable({
  providedIn: 'root'
})
export class TareasService {

  constructor(private http: HttpClient) { }



  cargarPruebas(desde: number = 0){
    const url = `${ base_url}prueba/obtener?desde=${desde}`; 
    return this.http.get<any>(url)
  }



  crearPrueba(formData: any){
    console.log('Creando prueba ...');
    return this.http.post(`${base_url}prueba`, formData)
    .pipe(
      tap( (resp: any) => { 
      })
    )
  }

  eliminarPrueba(id: any){
    const url = `${base_url}prueba/${id}`;
    return this.http.delete(url); 
  }



  
  exportPruebas(): Observable<any> {
    return this.http.get<any>(environment.baseUrl + 'prueba/exportar')
  }





  actualizarPesada(formData: any, id: string){
    return this.http.post(`${base_url}prueba/updatepre1/${id}`, formData)
    .pipe(
      tap( (resp: any) => { 
      })
    )
  }

  actualizarPesada2(formData: any, id: string){
    return this.http.post(`${base_url}prueba/updatepre2/${id}`, formData)
    .pipe(
      tap( (resp: any) => { 
      })
    )
  }


  actualizarPesada3(formData: any, id: string){
    return this.http.post(`${base_url}prueba/updatepre3/${id}`, formData)
    .pipe(
      tap( (resp: any) => { 
      })
    )
  }

  actualizarPesada4(formData: any, id: string){
    return this.http.post(`${base_url}prueba/updatepre4/${id}`, formData)
    .pipe(
      tap( (resp: any) => { 
      })
    )
  }

  actualizarPesada5(formData: any, id: string){
    return this.http.post(`${base_url}prueba/updatepre5/${id}`, formData)
    .pipe(
      tap( (resp: any) => { 
      })
    )
  }


  actualizarPesada6(formData: any, id: string){
    return this.http.post(`${base_url}prueba/updatepre6/${id}`, formData)
    .pipe(
      tap( (resp: any) => { 
      })
    )
  }

  actualizarPesada7(formData: any, id: string){
    return this.http.post(`${base_url}prueba/updatepre7/${id}`, formData)
    .pipe(
      tap( (resp: any) => { 
      })
    )
  }

  actualizarPesada8(formData: any, id: string){
    return this.http.post(`${base_url}prueba/updatepre8/${id}`, formData)
    .pipe(
      tap( (resp: any) => { 
      })
    )
  }


  actualizarPesada9(formData: any, id: string){
    return this.http.post(`${base_url}prueba/updatepre9/${id}`, formData)
    .pipe(
      tap( (resp: any) => { 
      })
    )
  }

  actualizarPesada10(formData: any, id: string){
    return this.http.post(`${base_url}prueba/updatepre10/${id}`, formData)
    .pipe(
      tap( (resp: any) => { 
      })
    )
  }



  export(): Observable<any> {
    return this.http.get<any>(environment.baseUrl + 'prueba/exportar')
  }






  cargarResultados(desde: number = 0){
    const url = `${ base_url}resultado/show?desde=${desde}`; 
    return this.http.get<any>(url)
  }



  cargarResultadosByID(formData: any){
    const url = `${ base_url}resultado/showByID`; 
    return this.http.post<any>(url, formData)
  }


  exportarResultados(formData: any){
    const url = `${ base_url}resultado/exportar`; 
    return this.http.post<any>(url, formData )
  }


  eliminarResultado(resultado: any){
    const url = `${base_url}resultado/${resultado._id}`;
    return this.http.delete(url); 
  }



}