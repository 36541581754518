import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CapacitacionesService } from '../services/capacitaciones.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Capacitacion } from 'src/app/entities/modulos/capacitacion';
import { Util } from 'src/app/utils/helpers/util';
import { CreateCapacitacionComponent } from '../modal/create-capacitacion/create-capacitacion.component';
import { DeleteCapacitacionComponent } from '../modal/delete-capacitacion/delete-capacitacion.component';
import { EditCapacitacionComponent } from '../modal/edit-capacitacion/edit-capacitacion.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-capacitaciones-list',
  templateUrl: './capacitaciones-list.component.html',
  styleUrls: ['./capacitaciones-list.component.scss']
})
export class CapacitacionesListComponent  implements OnInit {


  public data: any = [];
  public capacitacion: any[] = [];
  public capacitacionTemp: any[] = [];

proyectos: [] = [];


  public IMG;
  public REC;
  
  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalTrabajo: number = 0;

  constructor(public trabajoService: CapacitacionesService,
    public router: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.IMG = environment.cloudinary+'capacitacionesPortadas/';
    this.REC = environment.cloudinary+'capacitaciones/';
    this.cargarCapacitaciones();

  }




  cargarCapacitaciones(){
    this.cargando = true; 
    this.trabajoService.cargarCapacitacion(this.desde)
    .subscribe(({total, cursoCapacitacion}) => {
        this.totalTrabajo = total;
        if(cursoCapacitacion.length !== 0) { 
          this.capacitacion = cursoCapacitacion;
          console.log(cursoCapacitacion);
          this.capacitacionTemp = cursoCapacitacion;
        }
        this.cargando = false;
    })
  }





  openCreate(){
    const modalRef = this.modalService.open(CreateCapacitacionComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then(res => {
        this.cargarCapacitaciones();
      })
  }



  openEdit(data: Capacitacion) {
    const modalEdit = this.modalService.open(EditCapacitacionComponent, { size: 'lg', backdrop: 'static' })
    modalEdit.componentInstance.capacitacion = data
    modalEdit.result.then(res => {
      this.cargarCapacitaciones();
    })
  }

  openDelete(data: Capacitacion) {
    const deleteModal = this.modalService.open(DeleteCapacitacionComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.capacitacion = data
    deleteModal.result.then(res => {
      this.cargarCapacitaciones();
    })
  }

  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalTrabajo) {
      this.desde -= valor;
    }
    this.cargarCapacitaciones();
  }


  export(){
    this.trabajoService.export()
        .subscribe(res => { Util.download(res['data'], 'capacitacion'); console.log(res)});
  }


  buscar(termino: string) {
    if(termino.length === 0 ) {
      return this.capacitacion = this.capacitacionTemp;
    }
    this.trabajoService.buscar('nombreC', termino)
    .subscribe(resultados => {
      this.capacitacion = resultados;
    });
  }
  


  openAddModulo(data: any){
    console.log(data);
    this.router.navigate(["/admin/modulos.list/"+data._id])
  }





}

