import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { HeaderComponent } from '../header/header.component';
import { AuthGuard } from '../services/guard/auth.guard';
import { RepositorioListComponent } from './repositorio-list/repositorio-list.component';
import { CarpetaListComponent } from './carpeta-list/carpeta-list.component';
import { RecursoListComponent } from './recurso-list/recurso-list.component';


const routes: Routes = [
  {
    path: 'admin', canActivate: [AuthGuard], children: [
      { path: 'repositorios.list', component: RepositorioListComponent },
      { path: 'carpetas.list', component: CarpetaListComponent },
      { path: 'recursos.list/:id', component: RecursoListComponent },
      { path: '', component: HeaderComponent, outlet: 'header' },
      { path: '', component: SidebarComponent, outlet: 'sidebar' }
    ]
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class RepositoriosRoutingModule { }
