import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-carpeta',
  templateUrl: './edit-carpeta.component.html',
  styleUrls: ['./edit-carpeta.component.scss']
})
export class EditCarpetaComponent {

}
