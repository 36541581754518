import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-tareas',
  templateUrl: './edit-tareas.component.html',
  styleUrls: ['./edit-tareas.component.scss']
})
export class EditTareasComponent {

}
