import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Categoria } from '../../entities/modulos/categoria';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
const base_url = environment.url;

@Injectable({
  providedIn: 'root'
})
export class CategoriasService {

  formGroup: FormGroup;


  constructor(protected http: HttpClient,
    protected router: Router,
    protected fb: FormBuilder) {

      this.formGroup = this.fb.group({
        codigo: ['', [Validators.required]],
        nombre: ['', [Validators.required]],
      })      
    }  
    get form (): FormGroup { return this.formGroup; }


    set fillForm(grado: Categoria) {
      this.formGroup.get('codigo').setValue(grado.codigo)
      this.formGroup.get('nombre').setValue(grado.nombre)
      }


      cargarCategoria(desde: number = 0){
        const url = `${ base_url}categoria/show?desde=${desde}`; 
        return this.http.get<any>(url)
      }

      getById(trabajo: Categoria): Observable<Categoria> {
        return this.http.post<Categoria>(environment.baseUrl + 'categoria/showByID', trabajo)
      }
    
      create(data: Categoria): Observable<Categoria> {
        const fd = new FormData();
        fd.append('nombre',data.nombre);
        fd.append('codigo',data.codigo);
        return this.http.post<Categoria>(`${environment.baseUrl}categoria`, fd)
      }
    
      edit(grado: Categoria): Observable<Categoria> {
        return this.http.post<Categoria>(environment.baseUrl + 'categoria/update/' + grado._id, grado);
      }
    
      delete(grado: Categoria): Observable<Categoria> {
        return this.http.delete<Categoria>(environment.baseUrl + 'categoria/' + grado._id)
      }
    
    
      export(): Observable<Categoria> {
        return this.http.get<Categoria>(environment.baseUrl + 'categoria/exportar')
      }


}
