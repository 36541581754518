import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Herramienta } from '../../entities/modulos/herramienta';

const base_url = environment.url;



@Injectable({
  providedIn: 'root'
})
export class HerramientasService {


  formGroup: FormGroup;


  constructor(protected http: HttpClient,
              protected router: Router,
              protected fb: FormBuilder) {

                this.formGroup = this.fb.group({
                  categoria_id: ['', [Validators.required]],
                  nombre: ['', [Validators.required]],
                  descripcion: ['', [Validators.required]],
                  enlace: ['', [Validators.required]],
                  imagen: ['', [Validators.required]],
                })      
              }   
             
              
              get form (): FormGroup { return this.formGroup; }
  

              set fillForm(herramienta: Herramienta) {
                this.formGroup.get('categoria_id').setValue(herramienta.categoria_id)
                this.formGroup.get('nombre').setValue(herramienta.nombre)
                this.formGroup.get('descripcion').setValue(herramienta.descripcion)
                this.formGroup.get('enlace').setValue(herramienta.enlace)
                this.formGroup.get('imagen').setValue(herramienta.imagen)
                }
              


  cargarHerramienta(desde: number = 0){
    const url = `${ base_url}herramienta/show?desde=${desde}`; 
    return this.http.get<any>(url)
  }


  cargarCategorias(desde: number = 0){
    const url = `${ base_url}categoria/show?desde=${desde}`; 
    return this.http.get<any>(url)
  }


  getById(trabajo: Herramienta): Observable<Herramienta> {
    return this.http.post<Herramienta>(environment.baseUrl + 'herramienta/showByID', trabajo)
  }

  create(data: Herramienta): Observable<Herramienta> {
    const fd = new FormData();
    fd.append('categoria_id',data.categoria_id);
    fd.append('nombre',data.nombre);
    fd.append('descripcion',data.descripcion);
    fd.append('enlace',data.enlace);
    fd.append('imagen',data.imagen);
    return this.http.post<Herramienta>(`${environment.baseUrl}herramienta`, fd)
  }

  edit(data: Herramienta): Observable<Herramienta> {
    const fd = new FormData();
    fd.append('categoria_id',data.categoria_id);
    fd.append('nombre',data.nombre);
    fd.append('descripcion',data.descripcion);
    fd.append('enlace',data.enlace);
    fd.append('imagen',data.imagen);
    return this.http.post<Herramienta>(environment.baseUrl + 'herramienta/update/' + data._id, fd);
  }

  delete(herramienta: Herramienta): Observable<Herramienta> {
    return this.http.delete<Herramienta>(environment.baseUrl + 'herramienta/' + herramienta._id)
  }


  export(): Observable<Herramienta> {
    return this.http.get<Herramienta>(environment.baseUrl + 'herramienta/exportar')
  }


  buscar(
    tipo: 'nombreH'|'tecnico'|'sede' |'usuario' |'dni' | 'email' | 'compra' | '_id',
    termino: string
  ) {
    const url = `${base_url}todo/coleccion/${tipo}/${termino}`;
    return this.http.get<any[]>(url)
    .pipe(
      map( (resp: any) => resp.resultados)
    );
  }




}
