import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HerramientasService } from 'src/app/herramientas/services/herramientas.service';
import { CreateRecursoComponent } from '../modal/create-recurso/create-recurso.component';
import { RecursoService } from '../services/recurso.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DeleteRecursoComponent } from '../modal/delete-recurso/delete-recurso.component';


@Component({
  selector: 'app-recurso-list',
  templateUrl: './recurso-list.component.html',
  styleUrls: ['./recurso-list.component.scss']
})
export class RecursoListComponent implements OnInit {

  public idFolder : any;

  public recursos:  any [];

  public IMG;

  constructor(public recursoService: RecursoService,
    private route: ActivatedRoute,
    private modalService: NgbModal){
      this.route.params.subscribe(params => {
        this.idFolder= params['id'];
      });
    

  }

ngOnInit(): void {

  this.IMG = environment.cloudinary+'recursos/';
  this.cargarRecursos();
  
  
  
}


cargarRecursos(){
  console.log(this.idFolder);
  this.recursoService.getById({"carpeta_id": this.idFolder}).subscribe( resp => {
    console.log(resp.recurso);
    this.recursos = resp.recurso;
  })
}




  openCreate() {
    const modalRef = this.modalService.open(CreateRecursoComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then(res => {
        this.cargarRecursos();
      })
  }



  openDelete(data) {
    const deleteModal = this.modalService.open(DeleteRecursoComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.recurso = data
    deleteModal.result.then(res => {
      this.cargarRecursos();
    })
  }


 




}
