import { Component } from '@angular/core';

@Component({
  selector: 'app-tareas-form',
  templateUrl: './tareas-form.component.html',
  styleUrls: ['./tareas-form.component.scss']
})
export class TareasFormComponent {

}
