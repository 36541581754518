<div class="modal-header btn-primary">
    <h4 class="modal-title text-white">{{ formTitle }}</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">
    <form (ngSubmit)="onSubmit()" id="formUser" [formGroup]="formGroup">

            <!-- <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Usuario ID:</mat-label>
                    <input name="nombre" autocomplete="off" matInput formControlName="usuario_id" >
                </mat-form-field>
            </div> -->


            <div class="row" style="text-align: center; justify-content: center; " *ngIf="imagenObs">
                <div class="col-12">
                    
                    <img  [src]="imgSelect" alt="" width="150px" > 
                </div>
                <div class="col-12" style="padding-top: 10px;">
                    <input type="file" class="cz-file-drop-input" accept="image/*" (change)="imgSelectedImagen($event)">
                </div>
            </div>




            <div class="" style="padding-top: 25px;" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre capacitacion:</mat-label>
                    <input name="nombre" autocomplete="off" matInput formControlName="nombre" >
                </mat-form-field>
            </div>


            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Descripción capacitacion:</mat-label>
                    <textarea name="description" autocomplete="off" matInput formControlName="descripcion" rows="5" ></textarea>
                </mat-form-field>
            </div>


            <div class="row">
                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Fecha Inicio:</mat-label>
                        <input matInput datePickerFormat="YYYY/MM/DD" [matDatepicker]="picker"  formControlName="fecha_inicio">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-6" >
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Fecha Fin:</mat-label>
                        <input matInput datePickerFormat="YYYY/MM/DD" [matDatepicker]="picker2"  formControlName="fecha_fin">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Duracion:</mat-label>
                    <input name="description" autocomplete="off" matInput formControlName="duracion"  >
                </mat-form-field>
            </div>


            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Seleccionar Tipo:</mat-label>
                    <mat-select  formControlName="tipo">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let wType of tipos" [value]="wType.value">
                            {{wType.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div class="row" style="text-align: center; justify-content: center; ">
                <div class="col-12">
                    <i class="fas fa-folder" style="padding-right: 5px; color: #E29E18; font-size: 55px;"></i>
                    <p *ngIf="recursosObs">{{recursosText}}</p>
                    <input *ngIf="!recursosObs" type="text" name="recursos" matInput formControlName="recursos">
                </div>
                <div class="col-12" style="padding-top: 20px;">
                    <input type="file" class="cz-file-drop-input" accept="application/pdf" (change)="imgSelected($event)">
                </div>
            </div>
            
    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="disableControl" (click)="closeMOdal()">CANCELAR</button>
    <button type="submit" [ngClass]="{'btn-dark': (disableControl === false), 'btn-warning': (disableControl === true)}"
        class="btn btn-primary" [disabled]="disableControl" form="formUser">
        <i *ngIf="disableControl" class="fas fa-spin fa-cog"></i>
        {{ disableControl === true ? 'ENVIANDO' : 'ACEPTAR' }}</button>
</div>