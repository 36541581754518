import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { GradosService } from '../../services/grados.service';
import { CreateGradoComponent } from '../modal/create-grado/create-grado.component';
import { EditGradoComponent } from '../modal/edit-grado/edit-grado.component';
import { DeleteGradoComponent } from '../modal/delete-grado/delete-grado.component';
import { Grado } from 'src/app/entities/modulos/grado';
import { Util } from 'src/app/utils/helpers/util';

@Component({
  selector: 'app-grados-list',
  templateUrl: './grados-list.component.html',
  styleUrls: ['./grados-list.component.scss']
})
export class GradosListComponent  implements OnInit {


  public data: any = [];
  public herramienta: any[] = [];
  public herramientasTemp: any[] = [];

proyectos: [] = [];


  public IMG =  environment.cloudinary;
  
  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalTrabajo: number = 0;

  constructor(public gradoService: GradosService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.cargarHerramientas();
  }


  cargarHerramientas(){
    this.cargando = true; 
    this.gradoService.cargarGrado(this.desde)
    .subscribe(({total, grado}) => {
        this.totalTrabajo = total;
        if(grado.length !== 0) { 
          this.herramienta = grado;
          console.log(grado);
          this.herramientasTemp = grado;
        }
        this.cargando = false;
    })
  }





  openCreate(){
    const modalRef = this.modalService.open(CreateGradoComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then(res => {
        this.cargarHerramientas();
      })
  }



  openEdit(data: Grado) {
    const modalEdit = this.modalService.open(EditGradoComponent, { size: 'lg', backdrop: 'static' })
    modalEdit.componentInstance.grado = data
    modalEdit.result.then(res => {
      this.cargarHerramientas();
    })
  }

  openDelete(data: Grado) {
    const deleteModal = this.modalService.open(DeleteGradoComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.grado = data
    deleteModal.result.then(res => {
      this.cargarHerramientas();
    })
  }

  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalTrabajo) {
      this.desde -= valor;
    }
    this.cargarHerramientas();
  }


  export(){
    this.gradoService.export()
        .subscribe(res => { Util.download(res['data'], 'grados'); console.log(res)});
  }


  buscar(termino: string) {
    // if(termino.length === 0 ) {
    //   return this.trabajo = this.trabajoTemp;
    // }
    // this.trabajoService.buscar('trabajo', termino)
    // .subscribe(resultados => {
    //   this.trabajo = resultados;
    // });
  }
  


}
