<div class="modal-header btn-primary">
    <h4 class="modal-title text-white">{{ formTitle }}</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">
    <form (ngSubmit)="onSubmit()" id="formUser" [formGroup]="formGroup">



        <div class="" >
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Nombre repositorio:</mat-label>
                <input name="nombre" autocomplete="off" matInput formControlName="nombre" >
            </mat-form-field>
        </div>



        <div class="" >
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Area:</mat-label>
                <mat-select  formControlName="area_id" (selectionChange)="onSelectionChange($event)">
                    <mat-option>--</mat-option> 
                    <mat-option *ngFor="let wType of areas" [value]="wType._id" >
                        {{wType.nombre}}
                    </mat-option>
                </mat-select>
            
            </mat-form-field>
        </div>

        <div class="" >
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Usuario:</mat-label>
                <mat-select  formControlName="usuario_id" (selectionChange)="onSelectionChangeUser($event)">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let wType of admins" [value]="wType.id">
                        {{wType.nombre}}
                    </mat-option>
                </mat-select>
            
            </mat-form-field>
        </div>


            

            <!-- 
            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Descripción herramienta:</mat-label>
                    <textarea name="description" autocomplete="off" matInput formControlName="descripcion" rows="5" ></textarea>
                </mat-form-field>
            </div>

            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Enlace:</mat-label>
                    <input name="nombre" autocomplete="off" matInput formControlName="enlace" >
                </mat-form-field>
            </div> -->

            
            
            
    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="disableControl" (click)="closeMOdal()">CANCELAR</button>
    <button type="submit" [ngClass]="{'btn-dark': (disableControl === false), 'btn-warning': (disableControl === true)}"
        class="btn btn-primary" [disabled]="disableControl" form="formUser">
        <i *ngIf="disableControl" class="fas fa-spin fa-cog"></i>
        {{ disableControl === true ? 'ENVIANDO' : 'ACEPTAR' }}</button>
</div>