import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Herramienta } from '../../entities/modulos/herramienta';
import { environment } from 'src/environments/environment';
import { RepositoriosService } from '../services/repositorios.service';
import { AreasService } from 'src/app/configuraciones/services/areas.service';
import { UserService } from 'src/app/services/security/user.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-repositorio-form',
  templateUrl: './repositorio-form.component.html',
  styleUrls: ['./repositorio-form.component.scss']
})
export class RepositorioFormComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Output() submitEvent = new EventEmitter<Herramienta>();
  @Output() closeEvent =  new EventEmitter<boolean>();
  @Input() disableControl: boolean;
  @Input() formTitle: string;
  refreshTable = false;
  statusActive: boolean = false;
  public file :File;
  public imgSelect : String | ArrayBuffer = `${environment.cloudinary}herramientas/default_go6lhc.JPG`;


  public areas: any [];
  public admins: any [];
  constructor(protected fb: FormBuilder,
    protected activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public repositoriosService: RepositoriosService,
    public adminService: UserService,
    public areaService: AreasService){

  }


  ngOnInit(): void {
    this.cargarAreas();
    this.cargarAdmins();
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.formGroup.reset();
    if(this.formTitle === 'EDITAR REPOSITORIO'){
      // this.formGroup.get('username').disable();
      this.statusActive = false;
      // this.formLDAP = false;
    } else{
      // this.newUser = false;
      this.statusActive = true;

    }
    
  }

  cargarAreas(){
    this.areaService.export().subscribe(resp => {
      console.log(resp['data']);
      this.areas = resp['data'];
    })
  }


  cargarAdmins(){
    this.adminService.export2().subscribe( resp => {
      console.log(resp);
      this.admins = resp['data'];
    })
  }


  onSelectionChange(event: MatSelectChange): void {
    console.log(event.value);
    this.formGroup.controls.area_id.setValue(event.value);
  }

  onSelectionChangeUser(event2: MatSelectChange): void {
    console.log(event2.value);
    this.formGroup.controls.usuario_id.setValue(event2.value);
  }

  onSubmit() {
    console.log(this.formGroup.value);
    if (this.formGroup.valid) {
      this.submitEvent.emit(this.formGroup.value)
      this.formGroup.reset();
    }
  }



  closeMOdal() {
    this.closeEvent.emit(this.refreshTable)
    this.modalService.dismissAll();
  }
  





}
