<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-gray" >
                    <div class="row">
                        <div class="col-12">
                            <span class="letra-cabecera">Reporte de calificaciones</span>
                        </div>
                    </div>
                
                        <div class="row mt-4">

                            <div class="col-12 text-right">
                                <button  class="btn btn-success" (click)="exportarResuls()"> <i class="fa fa-download" aria-hidden="true"></i> &nbsp; Descargar Excel</button>
                               
                            </div>

                            <div class="col-12 text-right">


                            </div>
                        </div>
                </div>




                <div class="row">  








                    <div class="col-lg-12 col-xlg-12 col-md-12">
                
                
                
                        <div *ngIf="cargando">
                            <div class="col-12">
                                
                                <div class="alert alert-info text-center">
                                  <h4 class="alert-heading">Cargando</h4>
                                  <i class="fa fa-spin fa-refresh fa-2x"></i>
                                  <p class="mb-0">Por favor espere</p>  
                                </div>
                        
                            </div>
                        </div> 
                    
                
                       
                        <div class="row" *ngIf="!cargando">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                
                
                
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead class="bg-primary" style="color: #fff">
                                                    <tr>
                                                        <th>Fecha</th>
                                                       <th>Usuario</th>
                                                        <th>Prueba</th>
                                                         <!-- <th>DNI</th>
                                                        <th>Prueba</th> -->
                                                        <th>Calificacion</th>
                                                        <th>Rango</th>
                                                        <th>Porcentaje</th>
                
                
                                                        <th class="text-nowrap">Borrar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let resultado of resultado">
                
                
                                                        
                                                        <td>{{ resultado.created | date: 'medium' }} </td> 
                                                        <!-- <td>{{ resultado.alumno }} </td> 
                                                        <td>{{ resultado.seccion }} </td> 
                                                        <td>{{ resultado.usuario.dni }} </td> 
                
                                                        <td>{{ resultado.tituloCurso }} </td>  -->
                                                        <td>{{ resultado.usuario_id }} </td> 
                                                        <td>{{ resultado.nombrePrueba }} </td> 
                                                        <td>{{ resultado.nota }}.00 </td> 
                                                        <td>0 - 20 </td> 
                                                        <td>{{ resultado.nota * 100 / 20 }} % </td> 
                                                    
                                                        <td class="text-nowrap text-center">
                                                            
                                                            <button class="cursor btn btn-danger" (click)="eliminarResultado(resultado)" > <i class="fa fa-trash"> </i>  </button>
                                                        </td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                
                                    <button  (click)="cambiarPagina(-10)"   class="btn btn-secondary">Anteriores</button>
                                        &nbsp;
                                        <button  (click)="cambiarPagina(10)"  class="btn btn-secondary">Siguientes</button> 
                
                                    </div>
                                </div>
                            </div>
                        </div>
                
                
                
                    </div>
                
                
                
                </div>





            </div>
        </div>
    </div>
</div>
