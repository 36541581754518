import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Grado } from 'src/app/entities/modulos/grado';
import { environment } from 'src/environments/environment';
import { GradosService } from '../../services/grados.service';
import { AreasService } from '../../services/areas.service';
@Component({
  selector: 'app-areas-form',
  templateUrl: './areas-form.component.html',
  styleUrls: ['./areas-form.component.scss']
})
export class AreasFormComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Output() submitEvent = new EventEmitter<Grado>();
  @Output() closeEvent =  new EventEmitter<boolean>();
  @Input() disableControl: boolean;
  @Input() formTitle: string;
  refreshTable = false;
  statusActive: boolean = false;

  public categorias: any [];
  public grados: any [];

  constructor(protected fb: FormBuilder,
    protected activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public gradosService: GradosService,
    public areaService: AreasService){

  }


  ngOnInit(): void {
    this.cargarCatgeorias();
    this.cargarGrados();
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.formGroup.reset();
    if(this.formTitle === 'EDITAR AREA'){
      // this.formGroup.get('username').disable();
      this.statusActive = false;
      // this.formLDAP = false;
    } else{
      // this.newUser = false;
      this.statusActive = true;

    }
    
  }

  cargarGrados(){
    this.gradosService.export().subscribe(resp => {
      this.grados = resp['data'];
      // console.log(resp['data']);
    })
  }

  cargarCatgeorias(){
    this.areaService.cargarGrado().subscribe(resp => {
      console.log(resp.grado);
      this.categorias = resp.grado;
    })
  }


  onSubmit() {
    console.log(this.formGroup.value);
    if (this.formGroup.valid) {
      this.submitEvent.emit(this.formGroup.value)
      this.formGroup.reset();
    }
  }



  closeMOdal() {
    this.closeEvent.emit(this.refreshTable)
    this.modalService.dismissAll();
  }
  





}
