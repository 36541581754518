import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SnackbarHelper } from 'src/app/utils/helpers/snackbar-helper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RepositoriosService } from '../../services/repositorios.service';

@Component({
  selector: 'app-edit-repositorio',
  templateUrl: './edit-repositorio.component.html',
  styleUrls: ['./edit-repositorio.component.scss']
})
export class EditRepositorioComponent implements OnInit {

  formGroup: FormGroup;
  formLoaded = false
  disableControl = false;
  serverResponseJSON: any
  @Input() repositorio: any

  constructor(
    protected formService: RepositoriosService,
    protected activeModal: NgbActiveModal,
    private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.formService.getById({ "_id": this.repositorio._id}).subscribe(data => {
      console.log(data)
      console.log("cargo editar")
      this.formService.fillForm = data['repositorio'][0]
      this.formLoaded = true
    })
    this.formGroup = this.formService.form;
  }


  onSubmit(value: any) {
    console.log("enviar")
  this.disableForm()
      value._id = this.repositorio._id
      this.formService.edit(value).subscribe(data => {
        SnackbarHelper.show(this.snackBar, { msg: 'Editó con éxito', })
        this.enableForm()
        this.activeModal.close(true)
      }, (error) => {
        this.enableForm()
      })
  
    }


  disableForm(): void {
    this.disableControl = true
    this.formGroup.disable()
  }

  enableForm() {
    this.disableControl = false
      this.formGroup.enable()
  }


  onClose($res: any) {
    this.activeModal.close($res)
  }

}
