import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Capacitacion } from 'src/app/entities/modulos/capacitacion';
import { CapacitacionesService } from '../../services/capacitaciones.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarHelper } from 'src/app/utils/helpers/snackbar-helper';

@Component({
  selector: 'app-delete-capacitacion',
  templateUrl: './delete-capacitacion.component.html',
  styleUrls: ['./delete-capacitacion.component.scss']
})
export class DeleteCapacitacionComponent {
  @Input() capacitacion: Capacitacion
  deleted = false
  deleting = false

  constructor(protected activeModal: NgbActiveModal,
    protected capacitacionesService: CapacitacionesService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }


  closeModal() {
    this.activeModal.close(true)
  }

  delete() {
    this.deleting=true
    this.capacitacionesService.delete(this.capacitacion).subscribe(data => {

      SnackbarHelper.show(this.snackBar, { msg: 'Eliminado con éxito', })

      console.log(data);
      this.deleted = true
      this.deleting=false
      this.closeModal()
    })  
  }


}
