import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Carpeta } from '../../entities/modulos/carpeta';
import { Observable } from 'rxjs';
const base_url = environment.url;


@Injectable({
  providedIn: 'root'
})
export class CarpetaService {

  formGroup: FormGroup;


  constructor(protected http: HttpClient,
    protected router: Router,
    protected fb: FormBuilder) {

      this.formGroup = this.fb.group({
        nota: ['', [Validators.required]],
        observaciones: ['', [Validators.required]],
        area_id: ['', [Validators.required]],
        grado_id: ['', [Validators.required]],
        repositorio_id: ['', [Validators.required]],
      })      
    }  
    get form (): FormGroup { return this.formGroup; }


    set fillForm(repositorio: Carpeta) {
      this.formGroup.get('nota').setValue(repositorio.nota)
      this.formGroup.get('observaciones').setValue(repositorio.observaciones)
      this.formGroup.get('area_id').setValue(repositorio.area_id)
      this.formGroup.get('grado_id').setValue(repositorio.grado_id)
      this.formGroup.get('repositorio_id').setValue(repositorio.repositorio_id)
      }


      cargarCarpetas(desde: number = 0){
        const url = `${ base_url}carpeta/show?desde=${desde}`; 
        return this.http.get<any>(url)
      }

      getById(repositorio: any): Observable<any> {
        return this.http.post<any>(environment.baseUrl + 'carpeta/showByID', repositorio)
      }
      
      create(data: Carpeta): Observable<Carpeta> {
        const fd = new FormData();
        fd.append('nota',data.nota);
        fd.append('observaciones',data.observaciones);
        fd.append('area_id',data.area_id);
        fd.append('grado_id',data.grado_id);
        fd.append('repositorio_id',data.repositorio_id);
        return this.http.post<Carpeta>(`${environment.baseUrl}carpeta`, fd)
      }
    
      edit(repositorio: Carpeta): Observable<Carpeta> {
        return this.http.post<Carpeta>(environment.baseUrl + 'carpeta/update/' + repositorio._id, repositorio);
      }
    
      delete(repositorio: Carpeta): Observable<Carpeta> {
        return this.http.delete<Carpeta>(environment.baseUrl + 'carpeta/' + repositorio._id)
      }
    
    
      export(): Observable<Carpeta> {
        return this.http.get<Carpeta>(environment.baseUrl + 'carpeta/exportar')
      }


}
