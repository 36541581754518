import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateCarpetaComponent } from '../modal/create-carpeta/create-carpeta.component';
import { CarpetaService } from '../services/carpeta.service';
import { RepositoriosService } from '../services/repositorios.service';
import { DeleteCarpetaComponent } from '../modal/delete-carpeta/delete-carpeta.component';

@Component({
  selector: 'app-carpeta-list',
  templateUrl: './carpeta-list.component.html',
  styleUrls: ['./carpeta-list.component.scss']
})
export class CarpetaListComponent implements OnInit {

  public idFolder: any;


  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalRepositorio: number = 0;


  public data: any = [];
  public carpeta: any[] = [];
  public carpetaTemp: any[] = [];

  constructor(private route: ActivatedRoute,
          public router: Router,
          public carpetaService: CarpetaService,
          public repositorioService: RepositoriosService,
              private modalService: NgbModal){

  }

  ngOnInit(): void {
    this.cargarCarpetas();

    
   
    // carpetas por id para agregar cuando se ponga un select filter
    // this.carpetaService.getById({"repositorio_id": this.idFolder}).subscribe(resp => {
    //   console.log(resp.carpeta);
    //   console.log(this.idFolder);
    // })
  }

  cargarCarpetas(){
    this.cargando = true; 
    this.carpetaService.cargarCarpetas(this.desde)
    .subscribe(({total, carpeta}) => {
        this.totalRepositorio = total;
        if(carpeta.length !== 0) { 
          this.carpeta = carpeta;
          console.log(carpeta);
          this.carpetaTemp = carpeta;
        }
        this.cargando = false;
    })
  }


  openCreate(){
    const modalRef = this.modalService.open(CreateCarpetaComponent, { size: 'lg', backdrop: 'static' })
      modalRef.result.then(res => {
        // this.cargarHerramientas();
        this.cargarCarpetas();
      })
  }


  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalRepositorio) {
      this.desde -= valor;
    }
    this.cargarCarpetas();
  }



  openDelete(data){
    const deleteModal = this.modalService.open(DeleteCarpetaComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.carpeta = data
    deleteModal.result.then(res => {
      this.cargarCarpetas();
    })
  }

  openRecursos(data){
    console.log(data);
    this.router.navigateByUrl('/admin/recursos.list/'+data._id)
    localStorage.setItem('carpeta_id', data._id)
  }
}

