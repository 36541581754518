import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ConfiguracionRoutingModule } from './configuracion-routing.module';


import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '../utils/utils.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { InformacionFormComponent } from './informacion/informacion-form/informacion-form.component';
import { InformacionListComponent } from './informacion/informacion-list/informacion-list.component';
import { GiroListComponent } from './giro/giro-list/giro-list.component';
import { GiroFormComponent } from './giro/giro-form/giro-form.component';
import { ZonaFormComponent } from './zona/zona-form/zona-form.component';
import { ZonaListComponent } from './zona/zona-list/zona-list.component';
import { CreateZonaComponent } from './zona/modal/create-zona/create-zona.component';
import { DeleteZonaComponent } from './zona/modal/delete-zona/delete-zona.component';
import { EditZonaComponent } from './zona/modal/edit-zona/edit-zona.component';
import { ViewZonaComponent } from './zona/modal/view-zona/view-zona.component';
import { ViewGiroComponent } from './giro/modal/view-giro/view-giro.component';
import { EditGiroComponent } from './giro/modal/edit-giro/edit-giro.component';
import { DeleteGiroComponent } from './giro/modal/delete-giro/delete-giro.component';
import { CreateGiroComponent } from './giro/modal/create-giro/create-giro.component';
import { CreateInformacionComponent } from './informacion/modal/create-informacion/create-informacion.component';
import { EditInformacionComponent } from './informacion/modal/edit-informacion/edit-informacion.component';
import { DeleteInformacionComponent } from './informacion/modal/delete-informacion/delete-informacion.component';
import { ViewInformacionComponent } from './informacion/modal/view-informacion/view-informacion.component';


@NgModule({
  declarations: [
    InformacionFormComponent,
    InformacionListComponent,
    GiroListComponent,
    GiroFormComponent,
    ZonaFormComponent,
    ZonaListComponent,
    CreateZonaComponent,
    DeleteZonaComponent,
    EditZonaComponent,
    ViewZonaComponent,
    ViewGiroComponent,
    EditGiroComponent,
    DeleteGiroComponent,
    CreateGiroComponent,
    CreateInformacionComponent,
    EditInformacionComponent,
    DeleteInformacionComponent,
    ViewInformacionComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ConfiguracionRoutingModule,
    ReactiveFormsModule,

   
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    UtilsModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatPaginatorModule,
    NgbModule,

  ]
})
export class ConfiguracionModule { }
