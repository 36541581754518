import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Recurso } from '../../entities/modulos/recurso';
import { Observable } from 'rxjs';
const base_url = environment.url;


@Injectable({
  providedIn: 'root'
})
export class RecursoService {
  formGroup: FormGroup;


  constructor(protected http: HttpClient,
    protected router: Router,
    protected fb: FormBuilder) {

      this.formGroup = this.fb.group({
        nombre: ['', [Validators.required]],
        imagen: ['', [Validators.required]],
        carpeta_id: ['', [Validators.required]],
      })      
    }  
    get form (): FormGroup { return this.formGroup; }


    set fillForm(repositorio: Recurso) {
      this.formGroup.get('carpeta_id').setValue(repositorio.carpeta_id)
      this.formGroup.get('nombre').setValue(repositorio.nombre)
      this.formGroup.get('imagen').setValue(repositorio.imagen)
      
      }


      cargarRecursos(desde: number = 0){
        const url = `${ base_url}recurso/show?desde=${desde}`; 
        return this.http.get<any>(url)
      }

      getById(repositorio: any): Observable<any> {
        return this.http.post<any>(environment.baseUrl + 'recurso/showByID', repositorio)
      }
      
      create(data: Recurso): Observable<Recurso> {
        const fd = new FormData();
        fd.append('nombre',data.nombre);
        fd.append('carpeta_id',data.carpeta_id);
        fd.append('imagen',data.imagen);
        return this.http.post<Recurso>(`${environment.baseUrl}recurso`, fd)
      }
    
     
    
      delete(repositorio: Recurso): Observable<Recurso> {
        return this.http.delete<Recurso>(environment.baseUrl + 'recurso/' + repositorio._id)
      }
    
    
      export(): Observable<Recurso> {
        return this.http.get<Recurso>(environment.baseUrl + 'recurso/exportar')
      }


}
