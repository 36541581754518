import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapacitacionesRoutingModule } from './capacitaciones-routing.module';
import { CapacitacionesListComponent } from './capacitaciones-list/capacitaciones-list.component';
import { CapacitacionesFormComponent } from './capacitaciones-form/capacitaciones-form.component';
import { CreateCapacitacionComponent } from './modal/create-capacitacion/create-capacitacion.component';
import { DeleteCapacitacionComponent } from './modal/delete-capacitacion/delete-capacitacion.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { UtilsModule } from '../utils/utils.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { EditCapacitacionComponent } from './modal/edit-capacitacion/edit-capacitacion.component';
import { ModulosListComponent } from './modulos-list/modulos-list.component';
import { ModulosFormComponent } from './modulos-form/modulos-form.component';
import { CreateModuloComponent } from './modal/create-modulo/create-modulo.component';
import { EditModuloComponent } from './modal/edit-modulo/edit-modulo.component';
import { DeleteModuloComponent } from './modal/delete-modulo/delete-modulo.component';



@NgModule({
  declarations: [
    CapacitacionesListComponent,
    CapacitacionesFormComponent,
    CreateCapacitacionComponent,
    DeleteCapacitacionComponent,
    EditCapacitacionComponent,
    ModulosListComponent,
    ModulosFormComponent,
    CreateModuloComponent,
    EditModuloComponent,
    DeleteModuloComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    CapacitacionesRoutingModule,


    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    UtilsModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatPaginatorModule,
    NgbModule,

  ]
})
export class CapacitacionesModule { }
