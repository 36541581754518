import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarHelper } from 'src/app/utils/helpers/snackbar-helper';
import { GradosService } from 'src/app/configuraciones/services/grados.service';
import { RepositoriosService } from '../../services/repositorios.service';
import { CarpetaService } from '../../services/carpeta.service';

@Component({
  selector: 'app-create-carpeta',
  templateUrl: './create-carpeta.component.html',
  styleUrls: ['./create-carpeta.component.scss']
})
export class CreateCarpetaComponent implements OnInit{

  
  formGroup: FormGroup;
  disableControl = false;
  @Input() carpeta: any;


  constructor(protected formService: CarpetaService, 
    protected activeModal: NgbActiveModal,
    private snackBar: MatSnackBar) {

      this.formGroup = formService.form;
     this.formGroup.controls.nota.setValue('64615c5205140f34a8bfb03e')


      
     }

  ngOnInit(): void {
    console.log(this.carpeta);
    

    
    // this.formGroup.get('repositorio_id')?.setValue(this.carpeta)
    console.log(this.formGroup);
  }



  onClose($res: boolean) {
    this.activeModal.close($res)
  }


  onSubmit(value: any) {
    this.disableForm()
    this.formService.create(value).subscribe(data => {
      this.enableForm()
      SnackbarHelper.show(this.snackBar, { msg: 'Se creó con éxito', })
      this.activeModal.close(true)
    }, (error) => {
      this.enableForm()
    })
    
  }



  disableForm(): void {
    this.disableControl = true
    this.formGroup.disable()
  }

  enableForm() {
    this.disableControl = false
      this.formGroup.enable()
  }


}


