<div class="modal-header btn-primary">
    <h4 class="modal-title text-white">{{ formTitle }}</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form (ngSubmit)="onSubmit()" id="formUser" [formGroup]="formGroup">


        <div class="form-row">
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre de Usuario</mat-label>
                    <input autocomplete="off" matInput formControlName="nombre">
                </mat-form-field>
            </div>
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Rol</mat-label>
                    <mat-select (selectionChange)="changePwValidators($event)" formControlName="role">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let wType of role" [value]="wType.value">
                            {{wType.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>


        <div class="form-row">
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Correo electronico</mat-label>
                    <input autocomplete="off" matInput formControlName="email">
                </mat-form-field>
            </div>
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Contraseña</mat-label>
                    <input autocomplete="off" matInput formControlName="password_show">
                </mat-form-field>
            </div>
        </div>
        
        
        <!-- <div class="form-row">
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre de Usuario</mat-label>
                    <input autocomplete="off" matInput formControlName="username">
                </mat-form-field>
            </div>
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Tipo de Usuario</mat-label>
                    <mat-select (selectionChange)="changePwValidators($event)" formControlName="company">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let wType of workerType" [value]="wType.name">
                            {{wType.valor}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre completo</mat-label>
                    <input autocomplete="off" matInput formControlName="fullname">
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="formGroup.controls?.company.value=='externo'" class="form-row">
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Contraseña</mat-label>
                    <input autocomplete="off" type="password" matInput formControlName="password">
                </mat-form-field>
            </div>
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Confirmar contraseña</mat-label>
                    <input type="password" autocomplete="off" matInput formControlName="passwordr">
                    <mat-error *ngIf="formGroup.controls.passwordr.errors?.confirm">Las contraseñas no coinciden</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Correo</mat-label>
                    <input autocomplete="off" matInput formControlName="email">
                </mat-form-field>
            </div>
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Confirmar correo</mat-label>
                    <input autocomplete="off" matInput formControlName="emailr">
                    <mat-error *ngIf="formGroup.controls.emailr.errors?.confirm">Los correos no coinciden</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Estado</mat-label>
                    <mat-select formControlName="status">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let sts of statusEntity" [value]="sts.value">
                            {{sts.label}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formGroup.controls.status.errors?.required">Seleccionar estado</mat-error>
                </mat-form-field>
            </div>
            <div class="form-group col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Celular</mat-label>
                    <input autocomplete="off" matInput formControlName="cellphone">
                </mat-form-field>
            </div>
        </div> -->
        
       

        
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="disableControl" (click)="closeMOdal()">CANCELAR</button>
    <button type="submit" [ngClass]="{'btn-dark': (disableControl === false), 'btn-warning': (disableControl === true)}"
        class="btn btn-primary" [disabled]="disableControl" form="formUser">
        <i *ngIf="disableControl" class="fas fa-spin fa-cog"></i>
        {{ disableControl === true ? 'ENVIANDO' : 'ACEPTAR' }}</button>
</div>