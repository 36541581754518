<div class="main-content">
    <div class="row">


 


<ul class="nav nav-tabs" id="myTab" role="tablist" >
    <li class="nav-item" role="presentation" >
      <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Listar Pruebas</a>
    </li>
    <li class="nav-item" role="presentation"  >
      <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Agregar Pruebas para la APP</a>
    </li>
   
  </ul>


  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">


<div class="row">  

    <div class="col-lg-12 col-xlg-12 col-md-12">

     
    

        <div class="row"> 
            <div class="col-12">
                <div class="card">
                    <div class="card-body">


                        <div style="float: left;">
                            <h4 class="card-title">Total Pruebas   (  {{totalPrueba }} )    - {{desde}}</h4>
                            <h6 class="card-subtitle">Pruebas registradas para la APP</h6>    
                        </div>
                        
                        <div  style="float: right;">
                             <button class="btn btn-info" (click)="cargarCurso()"> <i class="fas fa-sync" aria-hidden="true"></i> &nbsp; Actualizar </button> &nbsp;                                                
                        </div>

                        <div class="table-responsive">
                        <br>
                            <table class="table table-bordered">
                                <thead class="bg-primary" style="color: #fff">
                                    <tr>
                                        <th>CREADO</th>
                                        <th>CURSO</th>
                                        <th>TITULO</th>
                                        <th>P 1</th>
                                        <th>p 2</th>
                                        <th>P 3</th>
                                        <th>P 4</th>
                                        <th>P 5</th>
                                        <th>P 6</th>
                                        <th>P 7</th>
                                        <th>P 8</th>
                                        <th>P 9</th>
                                        <th>P 10</th>
                                        
                                        <th class="text-nowrap">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let prueba of prueba">
                                        <td>{{ prueba.created | date: 'medium'}}</td>
                                        <td>{{ prueba.id_capacitacion}}</td>
                                        <td>{{ prueba.tituloPrueba}}</td>
                                        <td> 
                                            <button class="cursor btn btn-success" *ngIf="!prueba.pre1"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></button> 
                                            <button class="cursor btn btn-primary" *ngIf="prueba.pre1"><i class='fas fa-check-double'></i></button> 
                                        </td>
                                        <td> 
                                            <button class="cursor btn btn-success" *ngIf="!prueba.pre2"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> </button> 
                                            <button class="cursor btn btn-primary" *ngIf="prueba.pre2"><i class="fa fa-check"> </i> </button> 
                                        </td>
                                        <td> 
                                            <button class="cursor btn btn-success" *ngIf="!prueba.pre3"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></button> 
                                            <button class="cursor btn btn-primary" *ngIf="prueba.pre3"><i class="fa fa-check"> </i> </button> 
                                        </td>
                                        <td> 
                                            <button class="cursor btn btn-success" *ngIf="!prueba.pre4"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></button> 
                                            <button class="cursor btn btn-primary" *ngIf="prueba.pre4"><i class="fa fa-check"> </i> </button> 
                                        </td>
                                        <td> 
                                            <button class="cursor btn btn-success" *ngIf="!prueba.pre5"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></button> 
                                            <button class="cursor btn btn-primary" *ngIf="prueba.pre5"><i class="fa fa-check"> </i> </button> 
                                        </td>
                                        <td> 
                                            <button class="cursor btn btn-success" *ngIf="!prueba.pre6"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></button> 
                                            <button class="cursor btn btn-primary" *ngIf="prueba.pre6"><i class="fa fa-check"> </i> </button> 
                                        </td>
                                        <td> 
                                            <button class="cursor btn btn-success" *ngIf="!prueba.pre7"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></button> 
                                            <button class="cursor btn btn-primary" *ngIf="prueba.pre7"><i class="fa fa-check"> </i> </button> 
                                        </td>
                                        <td> 
                                            <button class="cursor btn btn-success" *ngIf="!prueba.pre8"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></button> 
                                            <button class="cursor btn btn-primary" *ngIf="prueba.pre8"><i class="fa fa-check"> </i> </button> 
                                        </td>
                                        <td> 
                                            <button class="cursor btn btn-success" *ngIf="!prueba.pre9"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></button> 
                                            <button class="cursor btn btn-primary" *ngIf="prueba.pre9"><i class="fa fa-check"> </i> </button> 
                                        </td>
                                        <td> 
                                            <button class="cursor btn btn-success" *ngIf="!prueba.pre10"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></button> 
                                            <button class="cursor btn btn-primary" *ngIf="prueba.pre10"><i class="fa fa-check"> </i> </button> 
                                        </td>

                                        <!-- <td>{{ prueba.pre1}}</td>
                                        <td>{{ prueba.pre2}}</td>
                                        <td>{{ prueba.pre3}}</td>
                                        <td>{{ prueba.pre4}}</td>
                                        <td>{{ prueba.pre5}}</td>
                                        <td>{{ prueba.pre6}}</td>
                                        <td>{{ prueba.pre7}}</td>
                                        <td>{{ prueba.pre8}}</td>
                                        <td>{{ prueba.pre9}}</td>
                                        <td>{{ prueba.pre10}}</td> -->

                                        <td class="text-nowrap text-center" >
                                            <a class="cursor btn btn-dark m-r-10 mr-2"    (click)="editar(prueba)"  data-toggle="modal" data-target=".bs-example-modal-lg"> <i class="fas fa-edit" style="color: #fff;"> </i>  </a>
                                            <button class="cursor btn btn-danger"  (click)="eliminarPrueba(prueba)" > <i class="fas fa-trash"> </i>  </button>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>


                        <div>

                        <button  (click)="cambiarPagina(-5)" class="btn btn-secondary">Anteriores</button>
                        &nbsp;
                        <button  (click)="cambiarPagina(5)" class="btn btn-secondary">Siguientes</button>
                        
                        </div>
                        

                    </div>
                </div>
            </div>
        </div>

     </div>


     <!-- <div class="col-lg-6 col-xlg-4 col-md-6" style="background: #fff; border-radius: 10px;">
        <div class="card-body text-center">

        </div>
    </div> -->


</div>




<div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true"  >
    <div class="modal-dialog modal-lg">
        <div class="modal-content" >
            <form [formGroup]="registerFormPre"   >

            <div class="modal-header bg-primary">
                <h4 class="modal-title" style="font-weight: bold; color: #fff;"  id="myLargeModalLabel">AGREGAR PREGUNTA A LA PRUEBA </h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
            

                <br>


                <div class="form-group ">
                    <small> PREGUNTAS 1 - 10</small>
                    <select type="text" name="role" class="form-control" (change)="onChange($event.target.value)" formControlName="nPregunta">
                        <option value="">Seleccione el Nº de pregunta</option>
                        <option value="pre1">PREGUNTA 1</option>
                        <option value="pre2">PREGUNTA 2</option>
                        <option value="pre3">PREGUNTA 3</option>
                        <option value="pre4">PREGUNTA 4</option>
                        <option value="pre5">PREGUNTA 5</option>
                        <option value="pre6">PREGUNTA 6</option>
                        <option value="pre7">PREGUNTA 7</option>
                        <option value="pre8">PREGUNTA 8</option>
                        <option value="pre9">PREGUNTA 9</option>
                        <option value="pre10">PREGUNTA 10</option>
                    </select>
                </div>    



                <div class="form-group text-center" >
                    <small> TITULO DE PREGUNTA </small>
                    <input   type="text" name="title"  class="form-control" formControlName="title" >
                </div>

                <div class="form-group text-center" >
                    <small> URL DE IMAGEN *opcional* </small>
                    <input   type="text" name="title"  class="form-control" formControlName="img" >
                </div>

                
                


                <div class="row form-group">
                    <div   class="col-sm">
                        <small> RESPUESTA 1</small>
                        <!-- <input   type="text" name="p1"  class="form-control"  formControlName="p1" > -->
                        <div class="input-group">
                            <input type="text" name="p1"  class="form-control"  formControlName="p1" #resp1>
                            <div class="input-group-prepend" (click)="printRespuesta(resp1)">
                                <span class="input-group-text btn btn-info bg-primary"><i style="cursor: pointer; color: #fff;" class="fas fa-thumbs-up"></i></span>
                              </div>
                        </div>
                    </div>
    
                    <div   class="col-sm">
                        <small> RESPUESTA 2</small>
                        <!-- <input   type="text" name="p2"  class="form-control"  formControlName="p2" > -->
                        <div class="input-group">
                            <input type="text" name="p2"  class="form-control"  formControlName="p2" #resp2>
                            <div class="input-group-prepend" (click)="printRespuesta(resp2)">
                                <span class="input-group-text btn btn-info bg-primary"><i style="cursor: pointer; color: #fff;" class="fas fa-thumbs-up"></i></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row form-group">
                    <div   class="col-sm">
                        <small> RESPUESTA 3</small>
                        <!-- <input   type="text" name="p3"  class="form-control"  formControlName="p3" > -->
                        <div class="input-group">
                            <input type="text" name="p3"  class="form-control"  formControlName="p3" #resp3>
                            <div class="input-group-prepend" (click)="printRespuesta(resp3)">
                                <span class="input-group-text btn btn-info bg-primary"><i style="cursor: pointer; color: #fff;" class="fas fa-thumbs-up"></i></span>

                            </div>
                        </div>
                    </div>

                    <div   class="col-sm">
                        <small> RESPUESTA 4</small>
                        <!-- <input   type="text" name="p4"  class="form-control"  formControlName="p4" > -->
                        <div class="input-group">
                            <input type="text" name="p4"  class="form-control"  formControlName="p4" #resp4>
                            <div class="input-group-prepend" (click)="printRespuesta(resp4)">
                                <span class="input-group-text btn btn-info bg-primary"><i style="cursor: pointer; color: #fff;" class="fas fa-thumbs-up"></i></span>

                            </div>
                        </div>
                    </div>
                </div>
               

        

                <div class="form-group text-center" >
                    <small> RESPUESTA CORRECTA</small>
                    <input style="color: green; font-size: 18px; font-weight: bold;"   type="text" name="pc"  class="form-control text-center"  formControlName="pc" readonly >
                </div>

           
            </div>
            <div class="modal-footer">
                    <!-- <button  (click)="agregar()"  class="btn btn-primary waves-effect text-left"  >AGREGAR  <b style="text-transform: uppercase;"> {{nPregunta}} </b> </button> -->

                    <button  (click)="actualizar()"  class="btn btn-success waves-effect text-left"  >GUARDAR  <b style="text-transform: uppercase;"> {{nPregunta}} </b> </button>
                    <!-- <button  (click)="actualizar2()"  class="btn btn-success waves-effect text-left"  >ADD P2</button> -->
                    <button id="close" type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">CERRAR</button>              
            </div>
        </form>
        </div>
    </div>
</div>



    </div>





 


        <!-- REGISTRAR -->





        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">



            <br>
            <div class="row">  
    
                <div class="col-lg-12 col-xlg-12 col-md-12" style="background: #fff; border-radius: 10px;">
                <div class="card-body">
                
                <form class="form-horizontal form-material text-center"
                [formGroup]="registerForm"  
                autocomplete="off"
                (ngSubmit)="crearPrueba()">


                 <img src="../assets/img/logo_ierp.png" alt="Home"  style="width: 100px;" />
                

                 <div class="form-group mt-4">
                    <select class="form-control"  formControlName="id_capacitacion" name="categoria" id="capacitaciones" placeholder="Seleccione una categoria" (change)="obetenerDataCategoria($event)">
                        <option value="" >Seleccione una categoria</option>
                        <option *ngFor="let data of capacitaciones" value="{{data._id}}">{{data.nombre}}</option>
                    </select>
                </div>


                <!-- <div class="form-group mt-4">
                    <select class="form-control"  formControlName="id_unidad" name="categoria" id="capacitaciones" placeholder="Seleccione una unidad" >
                        <option value="" >Seleccione una unidad</option>
                        <option *ngFor="let data of unidades" value="{{data._id}}">{{data.nombre}}</option>
                    </select>
                </div> -->



                 <!-- <div class="form-group pt-3">
                    <select class="form-control"  formControlName="codigoCurso" name="codigocurso" id="codigocurso" placeholder="Seleccione un curso">
                        <option value="" >Seleccione un curso</option>
                        <option *ngFor="let data of capacitaciones" value="{{data.codigo}}">{{curso.nombre}}</option>
                    </select>
                </div> -->
  

                <div class="form-group  pt-3">
                    <div>
                        <input class="form-control" type="text"   formControlName="tituloPrueba" placeholder="Titulo de la prueba">
                    </div>
                </div>
                


                
                <div class="form-group  pt-3">
                    <div>
                        <input class="form-control" type="text" formControlName="descripcionPrueba"   placeholder="Descripcion de la prueba " >
                    </div>
                </div>
    
              

            

                
           
                

                    <button    class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light mt-4" type="submit"> <i  style="padding-left: 15px;" class="fa fa-save"></i> Registrar Prueba </button>
                    </form>
                    </div>
                </div>
                
    
                <div class="col-lg-6 col-xlg-5 col-md-6">
                    <!-- <div id="carouselExampleInterval" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active" data-interval="500">
                            <img src="assets/images/newapp.png" class="d-block w-100" alt="...">
                          </div>
                          <div class="carousel-item" data-interval="500">
                            <img src="assets/images/noticias.png" class="d-block w-100" alt="...">
                          </div>
                  
                  
                  
                  
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleInterval" role="button" data-slide="prev">
                          <span  class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a  class="carousel-control-next" href="#carouselExampleInterval" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div> -->
                </div>
                
                </div>
            
            
            
        </div>





    

  </div>  











</div>
</div>