<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-gray" >
                    <div class="row">
                        <div class="col-12">
                            <span class="letra-cabecera">Graficas de resultados</span>
                        </div>
                    </div>



                    <div class="row">


                        <div class="col-6">

                        </div>

                        <div class="col-6">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Seleccionar la prueba :</mat-label>
                                    <mat-select  [(ngModel)]="titleTest" (selectionChange)="onSelectionChange($event)">
                                        <mat-option>--</mat-option>
                                        <mat-option *ngFor="let wType of titlePrueba" [value]="wType.tituloPrueba">
                                            {{wType.tituloPrueba}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                    </div>


                </div>



                <div class="card-body">

                    <div class="row">
                        <div class="col-6"  style="display: block; padding-top: 15px;">
                          <canvas baseChart
                                          [datasets]="barChartDataL"
                                          [labels]="barChartLabelsL"
                                          [options]="barChartOptionsL"
                                          [legend]="barChartLegendL"
                                          [chartType]="barChartTypeL">
                            </canvas>
                        </div>
                    
                        <div class="col-6"  style="display: block; padding-top: 15px;">
                          <canvas baseChart
                                          [datasets]="barChartDataL"
                                          [labels]="barChartLabelsL"
                                          [options]="barChartOptionsL"
                                          [legend]="barChartLegendL"
                                          [chartType]="barChartTypeL2">
                                      </canvas>
                        </div>
                    

                </div>


            </div>
        </div>
    </div>
</div>

