import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Capacitacion } from '../../entities/modulos/capacitacion';
import { map } from 'rxjs/operators';

const base_url = environment.url;



@Injectable({
  providedIn: 'root'
})
export class CapacitacionesService {


  formGroup: FormGroup;


  constructor(protected http: HttpClient,
              protected router: Router,
              protected fb: FormBuilder) {

                this.formGroup = this.fb.group({
                  usuario_id: ['', [Validators.required]],
                  nombre: ['', [Validators.required]],
                  descripcion: ['', [Validators.required]],
                  recursos: ['', [Validators.required]],
                  imagen: ['', [Validators.required]],
                  fecha_inicio: ['', [Validators.required]],
                  fecha_fin: ['', [Validators.required]],
                  duracion: ['', [Validators.required]],
                  tipo: ['', [Validators.required]],
                })      
              }   
             
              
              get form (): FormGroup { return this.formGroup; }
  

              set fillForm(herramienta: Capacitacion) {
                this.formGroup.get('usuario_id').setValue(herramienta.usuario_id)
                this.formGroup.get('nombre').setValue(herramienta.nombre)
                this.formGroup.get('descripcion').setValue(herramienta.descripcion)
                this.formGroup.get('recursos').setValue(herramienta.recursos)
                this.formGroup.get('imagen').setValue(herramienta.imagen)
                this.formGroup.get('fecha_inicio').setValue(herramienta.fecha_inicio)
                this.formGroup.get('fecha_fin').setValue(herramienta.fecha_fin)
                this.formGroup.get('duracion').setValue(herramienta.duracion)
                this.formGroup.get('tipo').setValue(herramienta.tipo)
                }
              


  cargarCapacitacion(desde: number = 0){
    const url = `${ base_url}cursoCapacitacion/show?desde=${desde}`; 
    return this.http.get<any>(url)
  }


  // cargarCategorias(desde: number = 0){
  //   const url = `${ base_url}cursoCapacitacion/show?desde=${desde}`; 
  //   return this.http.get<any>(url)
  // }


  getById(trabajo: Capacitacion): Observable<Capacitacion> {
    return this.http.post<Capacitacion>(environment.baseUrl + 'cursoCapacitacion/showByID', trabajo)
  }

  create(data: Capacitacion): Observable<Capacitacion> {
    const fd = new FormData();
    fd.append('usuario_id',data.usuario_id);
    fd.append('nombre',data.nombre);
    fd.append('descripcion',data.descripcion);
    fd.append('recursos',data.recursos);
    fd.append('imagen',data.imagen);
    fd.append('fecha_inicio',data.fecha_inicio);
    fd.append('fecha_fin',data.fecha_fin);
    fd.append('duracion',data.duracion);
    fd.append('tipo',data.tipo);
    return this.http.post<Capacitacion>(`${environment.baseUrl}cursoCapacitacion`, fd)
  }

  edit(trabajo: Capacitacion): Observable<Capacitacion> {
    return this.http.post<Capacitacion>(environment.baseUrl + 'cursoCapacitacion/update/' + trabajo._id, trabajo);
  }

  delete(herramienta: Capacitacion): Observable<Capacitacion> {
    return this.http.delete<Capacitacion>(environment.baseUrl + 'cursoCapacitacion/' + herramienta._id)
  }


  export(): Observable<Capacitacion> {
    return this.http.get<Capacitacion>(environment.baseUrl + 'cursoCapacitacion/exportar')
  }

  buscar(
    tipo: 'nombreC'|'tecnico'|'sede' |'usuario' |'dni' | 'email' | 'compra' | '_id',
    termino: string
  ) {
    const url = `${base_url}todo/coleccion/${tipo}/${termino}`;
    return this.http.get<any[]>(url)
    .pipe(
      map( (resp: any) => resp.resultados)
    );
  }


}
