import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { HeaderComponent } from '../header/header.component';
import { AuthGuard } from '../services/guard/auth.guard';
import { CapacitacionesListComponent } from './capacitaciones-list/capacitaciones-list.component';
import { ModulosListComponent } from './modulos-list/modulos-list.component';
import { ReporteGraphComponent } from '../configuraciones/reportes/reporte-graph/reporte-graph.component';



const routes: Routes = [
  {
    path: 'admin', canActivate: [AuthGuard], children: [
      { path: 'capacitaciones.list', component: CapacitacionesListComponent },
      { path: 'modulos.list/:id', component: ModulosListComponent  },
      { path: 'reporte.graph', component: ReporteGraphComponent  },
      { path: '', component: HeaderComponent, outlet: 'header' },
      { path: '', component: SidebarComponent, outlet: 'sidebar' }
    ]
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})

export class CapacitacionesRoutingModule { }
