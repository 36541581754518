<div class="modal-header btn-primary">
    <h4 class="modal-title text-white">{{ formTitle }}</h4>
    <button (click)="closeMOdal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">
    <form (ngSubmit)="onSubmit()" id="formUser" [formGroup]="formGroup">

            <!-- <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Usuario ID:</mat-label>
                    <input name="nombre" autocomplete="off" matInput formControlName="usuario_id" >
                </mat-form-field>
            </div> -->

            <div class="" style="padding-top: 25px; ">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Seleccionar tipo:</mat-label>
                    <mat-select  formControlName="tipo">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let wType of unidades" [value]="wType.value">
                            {{wType.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            

            <div class="" style="display: none!important;"  >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>ID Capacitacion:</mat-label>
                    <input name="id_capacitacion" autocomplete="off" matInput formControlName="id_capacitacion"  [(ngModel)]="unidad_id">
                </mat-form-field>
            </div>


            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre unidad:</mat-label>
                    <input name="nombre" autocomplete="off" matInput formControlName="nombre" >
                </mat-form-field>
            </div>


            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Video:</mat-label>
                    <input name="video" autocomplete="off" matInput formControlName="video"  >
                </mat-form-field>
            </div>



            <!-- <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Tarea:</mat-label>
                    <input name="tarea" autocomplete="off" matInput formControlName="tarea"  >
                </mat-form-field>
            </div> -->

            <div class="" style="padding-top: 25px; ">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Seleccionar tarea:</mat-label>
                    <mat-select  formControlName="tarea">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let wType of pruebas" [value]="wType._id">
                            {{wType.tituloPrueba}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>



           


            <div class="" >
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Enlace de meet:</mat-label>
                    <input name="meet" autocomplete="off" matInput formControlName="meet"  >
                </mat-form-field>
            </div>



        


            <div *ngIf="recursosObs" class="row" style="text-align: center; justify-content: center; ">
                <div class="col-12">
                    <i class="fas fa-folder" style="padding-right: 5px; color: #E29E18; font-size: 55px;"></i>
                    <p *ngIf="recursosObs">{{recursosText}}</p>
                    <input *ngIf="!recursosObs" type="text" name="material" matInput formControlName="material">
                </div>
                <div class="col-12" style="padding-top: 20px;">
                    <input type="file" class="cz-file-drop-input" accept="application/pdf" (change)="imgSelected($event)">
                </div>
            </div>

            
            
    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="disableControl" (click)="closeMOdal()">CANCELAR</button>
    <button type="submit" [ngClass]="{'btn-dark': (disableControl === false), 'btn-warning': (disableControl === true)}"
        class="btn btn-primary" [disabled]="disableControl" form="formUser">
        <i *ngIf="disableControl" class="fas fa-spin fa-cog"></i>
        {{ disableControl === true ? 'ENVIANDO' : 'ACEPTAR' }}</button>
</div>