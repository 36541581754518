import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Repositorio } from '../../entities/modulos/repositorio';
import { Observable } from 'rxjs';

const base_url = environment.url;

@Injectable({
  providedIn: 'root'
})
export class RepositoriosService {

  
  formGroup: FormGroup;


  constructor(protected http: HttpClient,
    protected router: Router,
    protected fb: FormBuilder) {

      this.formGroup = this.fb.group({
        nombre: ['', [Validators.required]],
        usuario_id: ['', [Validators.required]],
        area_id: ['', [Validators.required]],
      })      
    }  
    get form (): FormGroup { return this.formGroup; }


    set fillForm(repositorio: Repositorio) {
      this.formGroup.get('nombre').setValue(repositorio.nombre)
      this.formGroup.get('usuario_id').setValue(repositorio.usuario_id)
      this.formGroup.get('area_id').setValue(repositorio.area_id)
      }


      cargarRepositorio(desde: number = 0){
        const url = `${ base_url}repositorio/show?desde=${desde}`; 
        return this.http.get<any>(url)
      }

      getById(repositorio: Repositorio): Observable<Repositorio> {
        return this.http.post<Repositorio>(environment.baseUrl + 'repositorio/showByID', repositorio)
      }
    
      create(data: Repositorio): Observable<Repositorio> {
        const fd = new FormData();
        fd.append('nombre',data.nombre);
        fd.append('usuario_id',data.usuario_id);
        fd.append('area_id',data.area_id);
        return this.http.post<Repositorio>(`${environment.baseUrl}repositorio`, fd)
      }
    
      edit(repositorio: Repositorio): Observable<Repositorio> {
        return this.http.post<Repositorio>(environment.baseUrl + 'repositorio/update/' + repositorio._id, repositorio);
      }
    
      delete(repositorio: Repositorio): Observable<Repositorio> {
        return this.http.delete<Repositorio>(environment.baseUrl + 'repositorio/' + repositorio._id)
      }
    
    
      export(): Observable<Repositorio> {
        return this.http.get<Repositorio>(environment.baseUrl + 'repositorio/exportar')
      }


}
