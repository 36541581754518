import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepositoriosRoutingModule } from './repositorios-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { UtilsModule } from '../utils/utils.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RepositorioListComponent } from './repositorio-list/repositorio-list.component';
import { RepositorioFormComponent } from './repositorio-form/repositorio-form.component';
import { CreateRepositorioComponent } from './modal/create-repositorio/create-repositorio.component';
import { EditRepositorioComponent } from './modal/edit-repositorio/edit-repositorio.component';
import { DeleteRepositorioComponent } from './modal/delete-repositorio/delete-repositorio.component';
import { DeleteCarpetaComponent } from './modal/delete-carpeta/delete-carpeta.component';
import { EditCarpetaComponent } from './modal/edit-carpeta/edit-carpeta.component';
import { CreateCarpetaComponent } from './modal/create-carpeta/create-carpeta.component';
import { CarpetaListComponent } from './carpeta-list/carpeta-list.component';
import { CarpetaFormComponent } from './carpeta-form/carpeta-form.component';
import { RecursoListComponent } from './recurso-list/recurso-list.component';
import { RecursoFormComponent } from './recurso-form/recurso-form.component';
import { CreateRecursoComponent } from './modal/create-recurso/create-recurso.component';
import { DeleteRecursoComponent } from './modal/delete-recurso/delete-recurso.component';



@NgModule({
  declarations: [
    RepositorioListComponent,
    RepositorioFormComponent,
    CreateRepositorioComponent,
    EditRepositorioComponent,
    DeleteRepositorioComponent,
    DeleteCarpetaComponent,
    EditCarpetaComponent,
    CreateCarpetaComponent,
    CarpetaListComponent,
    CarpetaFormComponent,
    RecursoListComponent,
    RecursoFormComponent,
    CreateRecursoComponent,
    DeleteRecursoComponent
  ],
  imports: [
    CommonModule,
    RepositoriosRoutingModule,

    ReactiveFormsModule,
   
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    UtilsModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatPaginatorModule,
    NgbModule
  ]
})
export class RepositoriosModule { }
