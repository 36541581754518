import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
// import { Categoria } from 'src/app/models/categoria.model';
// import { Curso } from 'src/app/models/cursos.model';
// import { Prueba } from 'src/app/models/prueba.model';
// import { CategoriaService } from 'src/app/services/categoria.service';
// import { CursosService } from 'src/app/services/cursos.service';
import Swal from 'sweetalert2';
import { CategoriasService } from '../../services/categorias.service';
import { TareasService } from 'src/app/configuraciones/services/tareas.service';
import { CapacitacionesService } from 'src/app/capacitaciones/services/capacitaciones.service';
import { ModulosService } from 'src/app/capacitaciones/services/modulos.service';

@Component({
  selector: 'app-tareas-list',
  templateUrl: './tareas-list.component.html',
  styleUrls: ['./tareas-list.component.scss']
})
export class TareasListComponent  implements OnInit {


  public categoria: any[] = [];
  public curso: any[] = [];

  public imagen: any;
  public noimagen = 'assets/images/image_icon.png';


  public cargando: boolean = true;
  public desde: number = 0;
  public totalPrueba: number = 0;
  public pruebaTemp: any[] = [];
  public prueba: any[] = [];

  public formSubmitedPre = false;

  public registerFormpre;

  public registerFormPre = this.fb.group({
    nPregunta: ['',  Validators.required],
    title: ['',  Validators.required],
    img: [''],
    p1: ['',  Validators.required],
    p2: ['', Validators.required],
    p3: ['', Validators.required],
    p4: ['', Validators.required],
    pc: ['', Validators.required]
  });


  public formSubmited = false;
  public registerForm = this.fb.group({
    // id_unidad: ['',  Validators.required],
    id_capacitacion: ['',  Validators.required],
    tituloPrueba: ['', Validators.required],
    descripcionPrueba: ['', Validators.required],
    // imagen: ['', Validators.required]
  });




  public pregunta1:  any[] = [];

  // public pesadaTemp: Pesada[] = [];
  // [{"title": "¿Cual es el lenguaje de diseño?", "p1": "GO", "p2": "NODE", "p3": "CSS", "p4": "JS", "pc": "CSS"}]

  public nPregunta;
  public idPrueba;
  public ocultar = false;
  public capacitaciones: any;
  public pruebas: any;


  public unidades: any = [];

  constructor(public pruebaServices: TareasService,
              private fb: FormBuilder,
              public capacitacionesService: CapacitacionesService,
              public tareaService: TareasService,
              public moduloService: ModulosService,
              public categoriaService: CategoriasService) { }

  ngOnInit(): void {
    this.cargarCurso();
    this.cargarCapacitaciones();
    // this.cargarCategoria();
  }


  obetenerDataCategoria(data){
    console.log(data.target.value);

    this.moduloService.getByIdCapacitacion({id_capacitacion: data.target.value})
    .subscribe((unidad) => {
        this.unidades = unidad['unidad']
        console.log(this.unidades);
    })
  }


  cargarCapacitaciones(){
    this.capacitacionesService.export().subscribe(resp => {
      console.log(resp['data']);
      this.capacitaciones = resp['data'];
    })
  }

 



  // cargarCategoria(){
  //   this.categoriaService.cargarTodosCategoria()
  //   .subscribe(({categoria}) => {
  //       this.categoria = categoria;
  //       console.log('categoria');
  //       console.log(categoria);
  //   })
  // }

  // cargarCursos(){
  //   this.cursoService.cargarTodosCursos()
  //   .subscribe(({curso}) => {
  //       this.curso = curso;
  //       console.log('curso');
  //       console.log(curso);
  //   })
  // }

  

  cargarCurso(){
    this.cargando = true; 
    this.pruebaServices.cargarPruebas(this.desde)
    .subscribe(({total, prueba}) => {
        this.totalPrueba = total;
        if(prueba.length !== 0) { 
          this.prueba = prueba;
          console.log(prueba);
          this.pruebaTemp = prueba;
        }
        this.cargando = false;
    })
  }



  onChange(deviceValue) {
    console.log(deviceValue);
    this.nPregunta = deviceValue;
  }


  editar(prueba){
    this.idPrueba  = prueba._id;
  }


  agregar(){
    this.pregunta1.push(this.registerFormPre.value)
    console.log(this.pregunta1);
  }



  eliminarPrueba(prueba: any){
    console.log(prueba);
    Swal.fire({
      title: 'Borrar Prueba?',
      text: `Esta a punto de borrar la Prueba` ,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo!'
    }).then((result) => {
      if (result.value) {

        this.pruebaServices.eliminarPrueba(prueba._id)
        .subscribe(resp => {

      this.cargarCurso();

        Swal.fire('Prueba Borrada',`La prueba fue eliminada correctamente` , 'success')
      });
      this.cargarCurso();

      }
    })  
  }




  crearPrueba(){
    this.formSubmited = true;
    console.log(this.registerForm.value);
    if( this.registerForm.invalid) {
      return;
    }


    this.pruebaServices.crearPrueba(this.registerForm.value)
    .subscribe( resp => {
      console.log('prueba creado');
      this.cargarCurso();
      this.registerForm.reset();
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Prueba creada',
        showConfirmButton: false,
        timer: 2500
      })
      console.log(resp);
    }, (err) =>   {
      Swal.fire('Error',  err.error.msg, 'error');
    });


  }



  changeFoto(){
    var file = (<HTMLInputElement>document.getElementById("fupFoto")).files[0];
    var fileReader = new FileReader();
    fileReader.onloadend =  () => {
      this.imagen = fileReader.result
    }
    fileReader.readAsDataURL(file);
  }





  actualizar(){

    // this.pregunta1.push(this.registerFormPre.value)

    
    this.formSubmitedPre = true;
    if( this.registerFormPre.invalid) {
      return;
    }

    if(this.nPregunta == "pre1") {

    this.pregunta1.push(this.registerFormPre.value)
     
      
      let data:any = this.registerFormPre.value;
      data.pre1 = JSON.stringify(this.pregunta1);
      this.pregunta1 = [];
      console.log(data);

      this.pruebaServices.actualizarPesada(data, this.idPrueba)
      .subscribe( resp => {
      this.cargarCurso();
      this.registerFormPre.reset();
      Swal.fire('Pregunta Agregada',`La pregunta fue agregada correctamente` , 'success')
    } 
    );

    //     console.log('agregar pregunta 1');
    }
    if (this.nPregunta == "pre2")  {
    this.pregunta1.push(this.registerFormPre.value)


      let data:any = this.registerFormPre.value;
      data.pre2 = JSON.stringify(this.pregunta1);
      this.pregunta1 = [];
      console.log(data);

      this.pruebaServices.actualizarPesada2(data, this.idPrueba)
      .subscribe( resp => {
      this.cargarCurso();
      this.registerFormPre.reset();
      Swal.fire('Pregunta Agregada',`La pregunta fue agregada correctamente` , 'success')
    } 
    );
      
      console.log('agregar pregunta 2');
    }
    if (this.nPregunta == "pre3")  {
      this.pregunta1.push(this.registerFormPre.value)

      let data:any = this.registerFormPre.value;
      data.pre3 = JSON.stringify(this.pregunta1);
      this.pregunta1 = [];
      console.log(data);

      this.pruebaServices.actualizarPesada3(data, this.idPrueba)
      .subscribe( resp => {
      this.cargarCurso();
      this.registerFormPre.reset();
      Swal.fire('Pregunta Agregada',`La pregunta fue agregada correctamente` , 'success')
    } 
    );

      console.log('agregar pregunta 3');
    }
    if (this.nPregunta == "pre4")  {
      this.pregunta1.push(this.registerFormPre.value)

      let data:any = this.registerFormPre.value;
      data.pre4 = JSON.stringify(this.pregunta1);
      this.pregunta1 = [];
      console.log(data);

      this.pruebaServices.actualizarPesada4(data, this.idPrueba)
      .subscribe( resp => {
      this.cargarCurso();
      this.registerFormPre.reset();
      Swal.fire('Pregunta Agregada',`La pregunta fue agregada correctamente` , 'success')
    } 
    );
      console.log('agregar pregunta 4');
    }
    if(this.nPregunta == "pre5") {
      this.pregunta1.push(this.registerFormPre.value)

      let data:any = this.registerFormPre.value;
      data.pre5 = JSON.stringify(this.pregunta1);
      this.pregunta1 = [];
      console.log(data);

      this.pruebaServices.actualizarPesada5(data, this.idPrueba)
      .subscribe( resp => {
      this.cargarCurso();
      this.registerFormPre.reset();
      Swal.fire('Pregunta Agregada',`La pregunta fue agregada correctamente` , 'success')
    } 
    );
      console.log('agregar pregunta 5');
    }
    if (this.nPregunta == "pre6")  {
      this.pregunta1.push(this.registerFormPre.value)

      let data:any = this.registerFormPre.value;
      data.pre6 = JSON.stringify(this.pregunta1);
      this.pregunta1 = [];
      console.log(data);

      this.pruebaServices.actualizarPesada6(data, this.idPrueba)
      .subscribe( resp => {
      this.cargarCurso();
      this.registerFormPre.reset();
      Swal.fire('Pregunta Agregada',`La pregunta fue agregada correctamente` , 'success')
    } 
    );
      console.log('agregar pregunta 6');
    }
    if (this.nPregunta == "pre7")  {
      this.pregunta1.push(this.registerFormPre.value)


      let data:any = this.registerFormPre.value;
      data.pre7 = JSON.stringify(this.pregunta1);
      this.pregunta1 = [];
      console.log(data);

      this.pruebaServices.actualizarPesada7(data, this.idPrueba)
      .subscribe( resp => {
      this.cargarCurso();
      this.registerFormPre.reset();
      Swal.fire('Pregunta Agregada',`La pregunta fue agregada correctamente` , 'success')
    } 
    );
      console.log('agregar pregunta 7');
    }
    if (this.nPregunta == "pre8")  {
      this.pregunta1.push(this.registerFormPre.value)

      let data:any = this.registerFormPre.value;
      data.pre8 = JSON.stringify(this.pregunta1);
      this.pregunta1 = [];
      console.log(data);

      this.pruebaServices.actualizarPesada8(data, this.idPrueba)
      .subscribe( resp => {
      this.cargarCurso();
      this.registerFormPre.reset();
      Swal.fire('Pregunta Agregada',`La pregunta fue agregada correctamente` , 'success')
    } 
    );
      console.log('agregar pregunta 8');
    }
    if (this.nPregunta == "pre9")  {
      this.pregunta1.push(this.registerFormPre.value)

      let data:any = this.registerFormPre.value;
      data.pre9 = JSON.stringify(this.pregunta1);
      this.pregunta1 = [];
      console.log(data);

      this.pruebaServices.actualizarPesada9(data, this.idPrueba)
      .subscribe( resp => {
      this.cargarCurso();
      this.registerFormPre.reset();
      Swal.fire('Pregunta Agregada',`La pregunta fue agregada correctamente` , 'success')
    } 
    );

      console.log('agregar pregunta 9');
    }
    if (this.nPregunta == "pre10")  {

      this.pregunta1.push(this.registerFormPre.value)

      let data:any = this.registerFormPre.value;
      data.pre10 = JSON.stringify(this.pregunta1);
      this.pregunta1 = [];
      console.log(data);

      this.pruebaServices.actualizarPesada10(data, this.idPrueba)
      .subscribe( resp => {
      this.cargarCurso();
      this.registerFormPre.reset();
      Swal.fire('Pregunta Agregada',`La pregunta fue agregada correctamente` , 'success')
    } 
    );
      console.log('agregar pregunta 10');
    }



  }


  cambiarPagina( valor: number) {
    this.desde += valor;

    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalPrueba) {
      this.desde -= valor;
    }

    this.cargarCurso();
  }



  printRespuesta(resp1){
    this.registerFormPre.get('pc')?.setValue(resp1.value);
  }


}

