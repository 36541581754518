<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-gray" >
                    <div class="row">
                        <div class="col-12">
                            <span class="letra-cabecera">Reporte de calificaciones por usuario</span>
                        </div>
                    </div>



                    <div class="row">


                        <div class="col-6">

                        </div>

                        <div class="col-6">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Seleccionar la usuario :</mat-label>
                                    <mat-select   (selectionChange)="onSelectionChange($event)">
                                        <mat-option>--</mat-option>
                                        <mat-option *ngFor="let wType of usuarios" [value]="wType._id">
                                            {{wType.nombre}} {{wType.apellidos}} || {{wType.dni}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                    </div>


                </div>



                <div class="card-body">

                    <div class="table-responsive">
                        <br>
                        <table class="table table-bordered">
                            <thead style="background-color: #007BFF; color: #fff; font-weight: lighter;">
                                <tr>
                                    <th>Fecha</th>
                                     <th>Prueba</th>
                                     <th>Calificacion</th>
                                     <th>Rango</th>
                                     <th>Porcentaje</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let resultado of resultados">
                                    <td>{{ resultado.created | date: 'medium' }} </td> 
                                    <!-- <td>{{ resultado.alumno }} </td> 
                                    <td>{{ resultado.seccion }} </td> 
                                    <td>{{ resultado.usuario.dni }} </td> 

                                    <td>{{ resultado.tituloCurso }} </td>  -->
                                    <td>{{ resultado.nombrePrueba }} </td> 
                                    <td>{{ resultado.nota }}.00 </td> 
                                    <td>0 - 20 </td> 
                                    <td>{{ resultado.nota * 100 / 20 }} % </td> 
                                  
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>

                </div>


            </div>
        </div>
    </div>
</div>