import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../services/guard/auth.guard';
import { HeaderComponent } from '../header/header.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { GradosListComponent } from './grados/grados-list/grados-list.component';
import { AreasListComponent } from './areas/areas-list/areas-list.component';
import { CategoriaListComponent } from './categorias/categoria-list/categoria-list.component';
import { TareasListComponent } from './tareas/tareas-list/tareas-list.component';
import { ResultadoListComponent } from './resultados/resultado-list/resultado-list.component';
import { ReporteAlumnoComponent } from './resultados/reporte-alumno/reporte-alumno.component';

const routes: Routes = [
  {
    path: 'admin', canActivate: [AuthGuard], children: [
      { path: 'tareas.list', component: TareasListComponent },
      { path: 'grados.list', component: GradosListComponent },
      { path: 'areas.list', component: AreasListComponent },
      { path: 'categorias.list', component: CategoriaListComponent },
      { path: 'resultados.list', component: ResultadoListComponent },
      { path: 'reporte.alumno', component: ReporteAlumnoComponent },
      { path: '', component: HeaderComponent, outlet: 'header' },
      { path: '', component: SidebarComponent, outlet: 'sidebar' }
    ]
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ConfiguracionesRoutingModule { }
