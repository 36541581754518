import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EmpleadoService } from 'src/app/empleados/empleado/services/empleado.service';
import { LoginService } from 'src/app/services/security/login.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  today = new Date()
  user: string
  typeUser
  idPersonal


    valor
  constructor(protected loginService: LoginService,
    private docTitleService: Title,
    public empleadoService: EmpleadoService) {
    this.user = loginService.getLogin()['admin']?.nombre
    this.typeUser = this.loginService.getLogin()?.role
    this.idPersonal = loginService.getLogin()?.id


   }

  ngOnInit(): void {
    this.docTitleService.setTitle('Inicio - ' + environment.appTitle)
  }




  // openMenu() {
    
  //   if (this.sidebarContainer.nativeElement.classList.contains('open-sidebar')) {
  //     this.sidebarContainer.nativeElement.classList.remove('open-sidebar')
  //   } else {
  //     this.sidebarContainer.nativeElement.classList.add('open-sidebar')
  //   }
  // }

}
