import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Grado } from 'src/app/entities/modulos/grado';
import { Util } from 'src/app/utils/helpers/util';
import { CreateRepositorioComponent } from '../modal/create-repositorio/create-repositorio.component';
import { EditRepositorioComponent } from '../modal/edit-repositorio/edit-repositorio.component';
import { DeleteRepositorioComponent } from '../modal/delete-repositorio/delete-repositorio.component';
import { RepositoriosService } from '../services/repositorios.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-repositorio-list',
  templateUrl: './repositorio-list.component.html',
  styleUrls: ['./repositorio-list.component.scss']
})
export class RepositorioListComponent implements OnInit {


  public data: any = [];
  public repositorio: any[] = [];
  public repositorioTemp: any[] = [];

proyectos: [] = [];


  public IMG =  environment.cloudinary;
  
  public cargando: boolean = true;
  public desde: number = 0;
  public formSubmited = false;
  public totalRepositorio: number = 0;

  constructor(public repositoriosService: RepositoriosService,
    public router: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.cargarHerramientas();
  }


  cargarHerramientas(){
    this.cargando = true; 
    this.repositoriosService.cargarRepositorio(this.desde)
    .subscribe(({total, repositorio}) => {
        this.totalRepositorio = total;
        if(repositorio.length !== 0) { 
          this.repositorio = repositorio;
          console.log(repositorio);
          this.repositorioTemp = repositorio;
        }
        this.cargando = false;
    })
  }





  openCreate(){
    const modalRef = this.modalService.open(CreateRepositorioComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then(res => {
        this.cargarHerramientas();
      })
  }


  openFolder(data){
    console.log(data._id);
    this.router.navigateByUrl("admin/carpetas.list/"+data._id)
  }


  openEdit(data: Grado) {
    const modalEdit = this.modalService.open(EditRepositorioComponent, { size: 'lg', backdrop: 'static' })
    modalEdit.componentInstance.repositorio = data
    modalEdit.result.then(res => {
      this.cargarHerramientas();
    })
  }

  openDelete(data: Grado) {
    const deleteModal = this.modalService.open(DeleteRepositorioComponent, { size: 'lg', backdrop: 'static' })
    deleteModal.componentInstance.repositorio = data
    deleteModal.result.then(res => {
      this.cargarHerramientas();
    })
  }

  cambiarPagina( valor: number) {
    this.desde += valor;
    if(this.desde <0 ) {
      this.desde = 0;
    } else if (this.desde > this.totalRepositorio) {
      this.desde -= valor;
    }
    this.cargarHerramientas();
  }


  export(){
    this.repositoriosService.export()
        .subscribe(res => { Util.download(res['data'], 'repositorios'); console.log(res)});
  }


  buscar(termino: string) {
    // if(termino.length === 0 ) {
    //   return this.trabajo = this.trabajoTemp;
    // }
    // this.trabajoService.buscar('trabajo', termino)
    // .subscribe(resultados => {
    //   this.trabajo = resultados;
    // });
  }
  


}

