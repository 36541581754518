import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-tareas',
  templateUrl: './delete-tareas.component.html',
  styleUrls: ['./delete-tareas.component.scss']
})
export class DeleteTareasComponent {

}
