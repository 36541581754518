import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfiguracionesRoutingModule } from './configuraciones-routing.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { UtilsModule } from '../utils/utils.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AreasListComponent } from './areas/areas-list/areas-list.component';
import { AreasFormComponent } from './areas/areas-form/areas-form.component';
import { CreateAreaComponent } from './areas/modal/create-area/create-area.component';
import { DeleteAreaComponent } from './areas/modal/delete-area/delete-area.component';
import { EditAreaComponent } from './areas/modal/edit-area/edit-area.component';
import { GradosListComponent } from './grados/grados-list/grados-list.component';
import { GradosFormComponent } from './grados/grados-form/grados-form.component';
import { CreateGradoComponent } from './grados/modal/create-grado/create-grado.component';
import { EditGradoComponent } from './grados/modal/edit-grado/edit-grado.component';
import { DeleteGradoComponent } from './grados/modal/delete-grado/delete-grado.component';
import { CreateCategoriaComponent } from './categorias/modal/create-categoria/create-categoria.component';
import { EditCategoriaComponent } from './categorias/modal/edit-categoria/edit-categoria.component';
import { DeleteCategoriaComponent } from './categorias/modal/delete-categoria/delete-categoria.component';
import { CategoriaFormComponent } from './categorias/categoria-form/categoria-form.component';
import { CategoriaListComponent } from './categorias/categoria-list/categoria-list.component';
import { TareasListComponent } from './tareas/tareas-list/tareas-list.component';
import { TareasFormComponent } from './tareas/tareas-form/tareas-form.component';
import { CreateTareasComponent } from './tareas/modal/create-tareas/create-tareas.component';
import { DeleteTareasComponent } from './tareas/modal/delete-tareas/delete-tareas.component';
import { EditTareasComponent } from './tareas/modal/edit-tareas/edit-tareas.component';
import { ResultadoListComponent } from './resultados/resultado-list/resultado-list.component';
import { ReporteGraphComponent } from './reportes/reporte-graph/reporte-graph.component';
import { ChartsModule } from 'ng2-charts';
import { ReporteAlumnoComponent } from './resultados/reporte-alumno/reporte-alumno.component';


@NgModule({
  declarations: [
    AreasListComponent,
    AreasFormComponent,
    CreateAreaComponent,
    DeleteAreaComponent,
    EditAreaComponent,
    GradosListComponent,
    GradosFormComponent,
    CreateGradoComponent,
    EditGradoComponent,
    DeleteGradoComponent,
    CreateCategoriaComponent,
    EditCategoriaComponent,
    DeleteCategoriaComponent,
    CategoriaFormComponent,
    CategoriaListComponent,
    TareasListComponent,
    TareasFormComponent,
    CreateTareasComponent,
    DeleteTareasComponent,
    EditTareasComponent,
    ResultadoListComponent,
    ReporteGraphComponent,
    ReporteAlumnoComponent
  ],
  imports: [
    CommonModule,
    ConfiguracionesRoutingModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    UtilsModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatPaginatorModule,
    NgbModule,
    ChartsModule
  ]
})
export class ConfiguracionesModule { }
