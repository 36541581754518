import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cliente } from 'src/app/entities/modulos/cliente';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const base_url = environment.url;


@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  formGroup: FormGroup;nombreC


  constructor(protected http: HttpClient,
              protected router: Router,
              protected fb: FormBuilder) {

                this.formGroup = this.fb.group({
                  nombre: ['', [Validators.required]],
                  apellidos: ['', [Validators.required]],
                  email: ['', [Validators.required]],
                  celular: ['', [Validators.required]],
                  dni: ['', [Validators.required]],
                  password_show: [''],
                  area_id: [''],
                })      
              }



  get form (): FormGroup { return this.formGroup; }

  set fillForm(cliente: Cliente) {
  this.formGroup.get('nombre').setValue(cliente.nombre)
  this.formGroup.get('apellidos').setValue(cliente.apellidos)
  this.formGroup.get('dni').setValue(cliente.dni)
  this.formGroup.get('celular').setValue(cliente.celular)
  this.formGroup.get('email').setValue(cliente.email)
  this.formGroup.get('password_show').setValue(cliente.password_show)
  this.formGroup.get('area_id').setValue(cliente.area_id)
  
  }


 cargarClientes(desde: number = 0){
    const url = `${ base_url}user/obtener?desde=${desde}`; 
    return this.http.get<any>(url)
  }

  getById(cliente: Cliente): Observable<Cliente> {
    return this.http.post<Cliente>(environment.baseUrl + 'user/showByID', cliente)
  }

  create(cliente: Cliente): Observable<Cliente> {
    return this.http.post<Cliente>(`${environment.baseUrl}user/create`, cliente)
  }

  edit(cliente: Cliente): Observable<Cliente> {
    return this.http.post<Cliente>(environment.baseUrl + 'user/update/' + cliente._id, cliente);
  }

  delete(cliente: Cliente): Observable<Cliente> {
    return this.http.delete<Cliente>(environment.baseUrl + 'user/' + cliente._id)
  }



  buscarRuc(_ruc: string): Observable<any>{
    let data = {
      ruc: _ruc
    };
    let url = "https://www.softwarelion.xyz/api/sunat/consulta-ruc";
    let token = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjozMzIxLCJjb3JyZW8iOiJhbGFpbWVzNjRAZ21haWwuY29tIiwiaWF0IjoxNjY3MTQwNzU2fQ._FaxGDpjO4D_QLL5An7dc3uUdJWfEOsYyvlA70yMR3Q";
    let _headers = new HttpHeaders().set("Authorization", token);
    return this.http.post(url, data, {headers: _headers});
}


  export(): Observable<Cliente> {
    return this.http.get<Cliente>(environment.baseUrl + 'user/exportar')
  }


  buscar(
    tipo: 'nombre'|'tecnico'|'sede' |'usuario' |'dni' | 'email' | 'compra' | '_id',
    termino: string
  ) {
    const url = `${base_url}todo/coleccion/${tipo}/${termino}`;
    return this.http.get<any[]>(url)
    .pipe(
      map( (resp: any) => resp.resultados)
    );
  }


}