import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CapacitacionesService } from '../services/capacitaciones.service';
import { Herramienta } from '../../entities/modulos/herramienta';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/services/security/login.service';

interface HtmlInputEvent extends Event{
  target : HTMLInputElement & EventTarget;
} 


@Component({
  selector: 'app-capacitaciones-form',
  templateUrl: './capacitaciones-form.component.html',
  styleUrls: ['./capacitaciones-form.component.scss']
})
export class CapacitacionesFormComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Output() submitEvent = new EventEmitter<Herramienta>();
  @Output() closeEvent =  new EventEmitter<boolean>();
  @Input() disableControl: boolean;
  @Input() formTitle: string;
  refreshTable = false;
  statusActive: boolean = false;
  public file :File;
  public file2 :File;
  public imgSelect : String | ArrayBuffer = `${environment.cloudinary}herramientas/default_go6lhc.JPG`;


  public categorias: any [];

  public recursosText = 'Sin Recursos';
  public recursosObs = false;
  public imagenObs = false;
  public IMG;


  tipos = [
    {
      value: "Capacitación",
      label: "Capacitación"
    },
    {
      value: "Curso",
      label: "Curso"
    }
  ]

  constructor(protected fb: FormBuilder,
    protected activeModal: NgbActiveModal,
    private modalService: NgbModal,
    protected loginService: LoginService,
    public capacitacionesService: CapacitacionesService){



      
  }


  // get form (): FormGroup { return this.formGroup; }

  ngOnInit(): void {

    this.IMG = environment.cloudinary+'capacitacionesPortadas/';


    this.formGroup.reset();
    if(this.formTitle === 'EDITAR CAPACITACION'){
      // this.recursosText = this.formGroup.controls.recursos.value;
      this.recursosObs = false;
      this.imagenObs = false;
    } else{
      this.recursosObs = true;
      this.imagenObs = true;
      console.log('NUEVO??');
      this.formGroup.controls.usuario_id.setValue(this.loginService.getLogin()['admin'].nombre);

    }
  }


  onSubmit() {
    console.log(this.formGroup.value);
    if (this.formGroup.valid) {
      this.submitEvent.emit(this.formGroup.value)
      this.formGroup.reset();
    }
  }

  closeMOdal() {
    this.closeEvent.emit(this.refreshTable)
    this.modalService.dismissAll();
  }

  imgSelected(event: HtmlInputEvent){
    if(event.target.files  && event.target.files[0]){
        this.file = <File>event.target.files[0];
        this.recursosText = this.file.name;
        const reader = new FileReader();
        // reader.onload = e => this.imgSelect= reader.result;
        reader.readAsDataURL(this.file);
       console.log(this.file);
       this.formGroup.controls.recursos.setValue(this.file)
    }
  }


  imgSelectedImagen(event2: HtmlInputEvent){
    if(event2.target.files  && event2.target.files[0]){
        this.file2 = <File>event2.target.files[0];
        const reader = new FileReader();
        reader.onload = e => this.imgSelect= reader.result;
        reader.readAsDataURL(this.file2);
       console.log(this.file2);
       this.formGroup.controls.imagen.setValue(this.file2)
    }
  }


}
