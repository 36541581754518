import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarHelper } from 'src/app/utils/helpers/snackbar-helper';
import { CarpetaService } from '../../services/carpeta.service';
import { Repositorio } from 'src/app/entities/modulos/repositorio';

@Component({
  selector: 'app-delete-carpeta',
  templateUrl: './delete-carpeta.component.html',
  styleUrls: ['./delete-carpeta.component.scss']
})
export class DeleteCarpetaComponent {
  @Input() carpeta: Repositorio
  deleted = false
  deleting = false

  constructor(protected activeModal: NgbActiveModal,
    protected carpetaService: CarpetaService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }


  closeModal() {
    this.activeModal.close(true)
  }

  delete() {
    this.deleting=true
    this.carpetaService.delete(this.carpeta).subscribe(data => {

      SnackbarHelper.show(this.snackBar, { msg: 'Eliminado con éxito', })

      console.log(data);
      this.deleted = true
      this.deleting=false
      this.closeModal()
    })  
  }


}
