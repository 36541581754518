import { Component } from '@angular/core';

@Component({
  selector: 'app-create-tareas',
  templateUrl: './create-tareas.component.html',
  styleUrls: ['./create-tareas.component.scss']
})
export class CreateTareasComponent {

}
