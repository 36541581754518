<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-gray" >
                    <div class="row">
                        <div class="col-12">
                            <span class="letra-cabecera">Listado de Usuarios</span>
                        </div>
                    </div>
                
                        <div class="row mt-4">

                            <div style="float: left;">
                            </div>

                            <div class="col-12 text-right">
                                <button class="btn btn-primary" (click)="openCreate()" ><i class="fas fa-plus mr-1"> </i> NUEVO</button>&nbsp;
                            </div>
                        </div>
                </div>



                <div class="card-body">




                    <div class="table-responsive">
                        <br>
                        <table class="table table-bordered">
                            <thead style="background-color: #007BFF; color: #fff; font-weight: lighter;">
                                <tr>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>Rol</th>
                                    <th class="text-nowrap text-center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of usuario">

                                    <td>{{data.nombre}}</td>
                                    <td>{{data.email}}</td>
                                    <td *ngIf="data.role == 2">Administrador</td>
                                    <td *ngIf="data.role == 1">Staff</td>

                                    <td class="text-nowrap text-center">
                                        <!-- <button  class="cursor btn btn-secondary mr-2"   > <i class="fas fa-info-circle"> </i>  </button>  -->
                                        <button  class="cursor btn btn-warning mr-2" (click)="openEdit(data)" > <i class="fa fa-edit"> </i>  </button> 
                                        <button  class="cursor btn btn-danger mr-2" (click)="openDelete(data)"   > <i class="fas fa-trash-alt"> </i>  </button> 
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>

                <button  class="btn btn-secondary" (click)="cambiarPagina(-5)"> <i class="fas fa-arrow-left mr-1"> </i> Anteriores</button>
                    &nbsp;
                    <button  class="btn btn-success" (click)="cambiarPagina(5)">Siguientes  <i class="fas fa-arrow-right mr-1"> </i> </button> 

                </div>



            </div>
        </div>
    </div>
</div>